import styled from "@emotion/styled";
import { Typography } from "@mui/material";
export const Typography2 = styled(Typography)(({ theme }) => ({
  "&": {
    color: theme.palette.text.grayHeigh,
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: "400",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
