import { api } from "./api";
export const web2ProductApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    addWeb2Product: builder.mutation({
      query: (product) => {
        return {
          url: "/add-web2-product",
          method: "POST",
          body: product,
        };
      },
      invalidatesTags: ["getAllWeb2Product", "getAllWeb2FeaturedProduct"],
    }),
    getSingleWeb2Product: builder.query({
      query: (productId) => {
        return {
          url: `/find-web2-product/${productId}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleWeb2Product"],
    }),
    getAllWeb2Product: builder.query({
      query: () => {
        return {
          url: `/get-all-web2-products`,
          method: "GET",
        };
      },
      providesTags: ["getAllWeb2Product"],
    }),
    deleteSingleWeb2Product: builder.mutation({
      query: (productId) => {
        return {
          url: `/delete-web2-product/${productId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "getAllWeb2Product",
        "getSingleWeb2Product",
        "getAllWeb2FeaturedProduct",
      ],
    }),
    publishWeb2Product: builder.mutation({
      query: (publishPayload) => {
        const { id, status } = publishPayload;
        return {
          url: `/publish-unpublish-web2-product/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["getAllWeb2Product"],
    }),
    featuredWeb2Product: builder.mutation({
      query: (updatePayload) => {
        const { id, status } = updatePayload;
        return {
          url: `/featured-web2-product/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["getAllWeb2Product", "getAllWeb2FeaturedProduct"],
    }),
    getAllFeaturedWeb2Product: builder.query({
      query: () => {
        return {
          url: `/check-featured-web2-product`,
          method: "GET",
        };
      },
      providesTags: ["getAllWeb2FeaturedProduct"],
    }),
    updateWeb2Product: builder.mutation({
      query: (updatePayload) => {
        const { id, updateProduct } = updatePayload;
        return {
          url: `/update-web2-product/${id}`,
          method: "PUT",
          body: updateProduct,
        };
      },
      invalidatesTags: ["getAllWeb2Product", "getSingleWeb2Product"],
    }),
  }),
});

export const {
  useAddWeb2ProductMutation,
  useGetSingleWeb2ProductQuery,
  useDeleteSingleWeb2ProductMutation,
  usePublishWeb2ProductMutation,
  useGetAllWeb2ProductQuery,
  useFeaturedWeb2ProductMutation,
  useGetAllFeaturedWeb2ProductQuery,
  useUpdateWeb2ProductMutation,
} = web2ProductApi;
