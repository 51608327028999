import { api } from "./api";

export const collectionApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createCollection: builder.mutation({
      query: (collection) => {
        return {
          url: "/add-collection",
          method: "POST",
          body: collection,
        };
      },
      invalidatesTags: ["getAllCollection"],
    }),
    getSingleCollection: builder.query({
      query: (collectionId) => {
        return {
          url: `/get-collection/${collectionId}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleCollection"],
    }),
    getAllCollection: builder.query({
      query: () => {
        return {
          url: `/get-all-collection`,
          method: "GET",
        };
      },
      providesTags: ["getAllCollection"],
    }),
    getAllCollectionName: builder.query({
      query: () => {
        return {
          url: `/get-collection-name`,
          method: "GET",
        };
      },
    }),
    publishCollection: builder.mutation({
      query: (publishPayload) => {
        const { id, status } = publishPayload;
        return {
          url: `/publish-unpublish-collection/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["getAllCollection"],
    }),
    updateCollection: builder.mutation({
      query: (updatePayload) => {
        const { id, updateCollection } = updatePayload;
        return {
          url: `/update-collection/${id}`,
          method: "PUT",
          body: updateCollection,
        };
      },
      invalidatesTags: ["getSingleCollection", "getAllCollection"],
    }),
    featuredCollection: builder.mutation({
      query: (updatePayload) => {
        const { id, status } = updatePayload;
        return {
          url: `/featured-collection/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: [
        "getSingleCollection",
        "getAllCollection",
        "getAllFeaturedCollection",
      ],
    }),
    deleteSingleCollection: builder.mutation({
      query: (collectionId) => {
        return {
          url: `/remove-collection/${collectionId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["getAllCollection", "getAllFeaturedCollection"],
    }),
    getAllFeaturedCollection: builder.query({
      query: () => {
        return {
          url: `/check-featured-collection`,
          method: "GET",
        };
      },
      providesTags: ["getAllFeaturedCollection"],
    }),
    getAllFeaturedCollectionProducts: builder.mutation({
      query: (collectionId) => {
        return {
          url: `/check-featured-collection-product`,
          method: "POST",
          body: { id: collectionId },
        };
      },
      providesTags: ["getAllFeaturedCollection"],
    }),
  }),
});

export const {
  useCreateCollectionMutation,
  useGetSingleCollectionQuery,
  usePublishCollectionMutation,
  useGetAllCollectionQuery,
  useGetAllCollectionNameQuery,
  useDeleteSingleCollectionMutation,
  useUpdateCollectionMutation,
  useFeaturedCollectionMutation,
  useGetAllFeaturedCollectionQuery,
  useGetAllFeaturedCollectionProductsMutation,
} = collectionApi;
