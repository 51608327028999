import Backdrop from "@mui/material/Backdrop";
import { Box, useMediaQuery } from "@mui/material";
import { logo } from "./Images";

export default function Loading({ loading }) {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <div>
      <Backdrop
        sx={{
          zIndex: 220000000,
          backgroundColor: "#fff",
        }}
        open={loading ?? false}
      >
        {/* <MoonLoader color="#1AC89C" size={130} /> */}
        <Box
          width="100%"
          height="100%"
          sx={{
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            className="fadeinout"
            width={matches ? "100px" : "180px"}
            alt=""
          />
        </Box>
      </Backdrop>
    </div>
  );
}
