import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Web2ProductList from "./Web2ProductList";
import { Link } from "react-router-dom";
import { useGetAllWeb2ProductQuery } from "../store/web2ProductApi";

import StyledTabs from "./StyledTabs";
import Loading from "../Components/SmallComponents/loading";

const btnName = ["PUBLISHED", "UNPUBLISHED"];
const Web2Product = () => {
  const matches = useMediaQuery("(max-width:960px)");
  const [tabsValue, setTabsValue] = useState(0);

  const {
    data = [],
    isError,
    isLoading,
  } = useGetAllWeb2ProductQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isError) {
      return console.log(isError);
    }
  }, [isError]);

  return (
    <>
      <Loading loading={isLoading} />
      <Box sx={{ pt: { xs: 5, md: 10 } }}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h1" mb={matches ? 2 : 0}>
              PRODUCTS ({data?.totalProducts ?? 0})
            </Typography>
            <Link to="/createweb2product" style={{ textDecoration: "none" }}>
              <Button variant="gradient" sx={{ width: "236px" }}>
                CREATE PRODUCT
              </Button>
            </Link>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box mb={matches ? 2 : 0}>
              <StyledTabs
                btnName={btnName}
                setTabsValue={setTabsValue}
                tabsValue={tabsValue}
              />
            </Box>
          </Box>
          {tabsValue === 0 ? (
            <Web2ProductList
              products={data?.publisedProducts ?? []}
              message={"All products are un-published!"}
            />
          ) : (
            <Web2ProductList
              products={data?.unPublisedProducts ?? []}
              message={"All products are published!"}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Web2Product;
