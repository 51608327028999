import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DiscountList from "./DiscountList";
import { Link } from "react-router-dom";
import { useGetAllDiscountQuery } from "../store/dicountApi";

// import StyledTabs from "./StyledTabs";
import Loading from "../Components/SmallComponents/loading";

// const btnName = ["PUBLISHED", "UNPUBLISHED"];
export default function DiscountPage() {
  const matches = useMediaQuery("(max-width:960px)");
  // const [tabsValue, setTabsValue] = useState(0);

  const { data = [], isError, isLoading } = useGetAllDiscountQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isError) {
      return console.log(isError);
    }
  }, [isError]);
  return (
    <>
      <Loading loading={isLoading} />
      <Box sx={{ pt: { xs: 5, md: 10 } }}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h1" mb={matches ? 2 : 0}>
              DISCOUNTS ({data?.data?.length ?? 0})
            </Typography>
            <Link to="/creatediscount" style={{ textDecoration: "none" }}>
              <Button variant="gradient" sx={{ width: "236px" }}>
                Create Discount
              </Button>
            </Link>
          </Box>
          <Box>
            <DiscountList data={data} />
          </Box>
        </Container>
      </Box>
    </>
  );
}
