import "./App.css";
import { Route, Routes } from "react-router-dom";
// import Header from "./Components/Header";
import CreateCollection from "./Components/CreateCollection";
import CreateProduct from "./Components/CreateProduct";
import Collections from "./Pages/Collections";
// import Footer from "./Pages/Footer";
import SignIn from "./Pages/SignIn";
import { Order, Web2Product } from "./Pages";
import SaveCollection from "./Pages/SaveCollection";
import SaveProduct from "./Pages/SaveProduct";
import EditCollectionPage from "./Pages/EditCollectionPage";
import EditProductPage from "./Pages/EditProductPage";
import EditWeb2Product from "./Pages/EditWeb2Product";
import { OrderDetail } from "./Components/OrderComponents/OrderDetail";
import PrivateRoute from "./PrivateRoute";
import { useDispatch } from "react-redux";
import {
  addTokenStore,
  collectionStateHandle,
  collectionProductDeleteHandle,
} from "./store/componentState";
import {
  getToken,
  collectionDetail,
  getCollectionProductDeleteDetail,
} from "./services/localStorage";
import { useEffect } from "react";
import CreateWweb2Product from "./Components/CreateWeb2Product";
import Web2SaveProduct from "./Pages/Web2SaveProduct";
import DeleteConfirm from "./Components/SmallComponents/DeleteConfirm";
import ErrorModal from "./Components/SmallComponents/ErrorModal";
import OrderProcessedModal from "./Components/SmallComponents/orderProcessedModal";
import DiscountPage from "./Pages/DiscountPage";
import CreateDiscount from "./Components/CreateDiscount";
// import PageNotFound from "./Pages/PageNotFound";

function App() {
  const dispatch = useDispatch();
  // const token = useSelector((state) => state.componentState.token);

  useEffect(() => {
    dispatch(addTokenStore(getToken));
    dispatch(collectionStateHandle(JSON.parse(collectionDetail)));
    dispatch(collectionProductDeleteHandle(getCollectionProductDeleteDetail));
  }, [dispatch]);
  return (
    <>
      <DeleteConfirm />
      <ErrorModal />
      <OrderProcessedModal />
      <Routes>
        <Route path={"/signin"} element={<SignIn />} />
        <Route exact path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Collections />} />
          <Route path="/createcollection" element={<CreateCollection />} />
          <Route path="/editcollection/:id" element={<EditCollectionPage />} />
          <Route path="/createproduct" element={<CreateProduct />} />
          <Route path="/editproduct/:id" element={<EditProductPage />} />
          <Route path="/createweb2product" element={<CreateWweb2Product />} />
          <Route path="/editweb2product/:id" element={<EditWeb2Product />} />
          <Route path="/web2product" element={<Web2Product />} />
          <Route path="/web2saveproduct/:id" element={<Web2SaveProduct />} />
          <Route path="/order" element={<Order />} />
          <Route path="/savecollection/:id" element={<SaveCollection />} />
          <Route path="/saveproduct/:id" element={<SaveProduct />} />
          <Route path="/orderDetail/:id" element={<OrderDetail />} />
          <Route path="/discount" element={<DiscountPage />} />
          <Route path="/creatediscount" element={<CreateDiscount />} />
        </Route>
        {/* <Route path="*" element={<PageNotFound token={token} />} /> */}
      </Routes>
    </>
  );
}

export default App;
