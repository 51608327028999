import { api } from "./api";

export const adminAuthApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    adminInfo: builder.mutation({
      query: (user) => {
        return {
          url: "/loginAdmin",
          method: "POST",
          body: user,
        };
      },
    }),
  }),
});

export const { useAdminInfoMutation } = adminAuthApi;
