import React from "react";
import { Button } from "@mui/material";

export const CustomBtn = ({ confirm }) => {
  return (
    <>
      <Button
        sx={{
          fontFamily: "Open Sans",
          width: "100%",
          height: "40px",
          padding: "5px",
          float: "right",
          color: "#ffffff",
          background: "#EF5656",
          zIndex: 1,
          "&:hover": {
            background: "#EF5656",
          },
          borderRadius: "14px",
        }}
      >
        {confirm}
      </Button>
    </>
  );
};

export const CustomOrderBtn = ({ confirm }) => {
  return (
    <>
      <Button
        sx={{
          fontFamily: "Open Sans",
          width: "100%",
          height: "40px",
          padding: "5px",
          float: "right",
          color: "#ffffff",
          background:
            "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%);",
          zIndex: 1,
          "&:hover": {
            background:
              "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%);",
          },
          borderRadius: "14px",
        }}
      >
        {confirm}
      </Button>
    </>
  );
};
