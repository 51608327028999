import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { confirmDelete } from "../SmallComponents/Images";
import { CustomBtn } from "./CustomBtn";
import CustomCencelBtn from "./cutomCencelBtn";
import { useSelector, useDispatch } from "react-redux";
import { openDeleteModal } from "../../store/componentState";
export default function DeleteConfirm() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.componentState.deleteConfirm);

  const handleClose = () => {
    dispatch(openDeleteModal({ open: false, deleteFunction: "" }));
  };

  return (
    <>
      <Dialog
        open={state.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 100,
          "& .MuiPaper-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            width: { xs: "100%", sm: "60%", md: "40%" },
            border: "1px solid #8C9296",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: "100px", height: "100px", mx: "auto" }}>
            <img
              src={confirmDelete}
              alt=""
              srcSet=""
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: "20px",
            }}
          >
            Confirm Delete!
          </DialogTitle>

          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Open Sans",
              color: "rgba(18, 27, 37, 0.69)",
              fontSize: { xs: "15px", md: "16px" },
              width: "90%",
              mx: "auto",
              my: 1,
            }}
          >
            This action cannot be undone.
          </Typography>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width="150px" onClick={handleClose}>
              <CustomCencelBtn cencel={"cancel"} />
            </Box>
            <Box
              sx={{ width: "150px" }}
              onClick={() => state.deleteFunction(state.id)}
            >
              <CustomBtn confirm={"confirm"} />
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
