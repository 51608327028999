import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "../utils";

export const api = createApi({
  reducerPath: "api",
  tagTypes: [
    "getSingleCollection",
    "getAllCollection",
    "getAllFeaturedCollection",
    "getSingleWeb3Product",
    "getAllWeb2Product",
    "getSingleWeb2Product",
    "getAllWeb2Product",
    "getAllWeb2FeaturedProduct",
    "getAllOrder",
    "getSingleOrder",
    "getAllDisounts",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
