import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { wrong, trueLogo, downloadbtn } from "../SmallComponents/Images";
import React, { useState, useEffect } from "react";
import { Typography2 } from "./Typography2";
import { OrderSumarry } from "./OrderSumarry";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetSingleOrdersQuery,
  useProcessedOrderMutation,
} from "../../store/OrderApi";
import { BackButton } from "../SmallComponents/BackButton";
import { useDispatch } from "react-redux";
import {
  openErrorModal,
  openOrderProcessedModal,
} from "../../store/componentState";
import Loading from "../SmallComponents/loading";
export const OrderDetail = () => {
  const matches = useMediaQuery("(max-width:960px)");
  const [show, setShow] = useState(false);
  const handlechange = () => {
    setShow(!show);
  };
  // const downloadImage = (img) => {
  //   saveAs(img, "image.png");
  // };

  const downloadImage = async (imageUrl) => {
    try {
      if (imageUrl?.slice(0, 21) === "https://ipfs.io/ipfs/") {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `file-${Date.now()}.png`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        const a = document.createElement("a");
        a.href = imageUrl;
        a.download = `file-${Date.now()}.png`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const [processed, setProcessed] = useState(false);
  const { data = [], isLoading } = useGetSingleOrdersQuery(params?.id);
  const [processedOrdersApi] = useProcessedOrderMutation();

  const processedOrder = async (id) => {
    try {
      const { data, error } = await processedOrdersApi({
        id: id,
        status: true,
      });
      if (error) {
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }

      setProcessed(data?.status);

      dispatch(
        openOrderProcessedModal({
          open: false,
          processedOrderFunction: "",
          id: "",
        })
      );

      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setProcessed(data?.orderDetail?.processed ?? false);
  }, [data?.orderDetail?.processed]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loading loading={isLoading} />
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px 0px",
            my: 5,
          }}
        >
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtypo"
                mb={matches ? 1 : 0}
                sx={{
                  fontSize: { xs: "0.9rem", md: "1.5rem" },
                  fontWeight: "700",
                }}
              >
                ORDER NO. {data?.orderDetail?.orderNo || null}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                ml={2}
                mb={matches ? 1 : 0}
              >
                <img
                  src={processed ? trueLogo : wrong}
                  width="20px"
                  height="20px"
                  alt=""
                />
                <Typography
                  ml={1}
                  sx={{
                    color: "gradienttext",
                    background: processed
                      ? "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)"
                      : "#EF5656",
                    webkitBackgroundClip: "text",
                    webkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    fontSize: matches ? "13px" : "18px",
                    fontWeight: 700,
                    fontFamily: "Open Sans",
                  }}
                >
                  {processed ? "PROCESSED" : "NOT PROCESSED"}
                </Typography>
              </Box>
            </Box>
            {!processed ? (
              <Button
                variant="gradient"
                onClick={() =>
                  dispatch(
                    openOrderProcessedModal({
                      open: true,
                      processedOrderFunction: processedOrder,
                      id: params?.id,
                    })
                  )
                }
                sx={{
                  width: matches ? "fit-content" : "252px",
                }}
              >
                Mark as Processed
              </Button>
            ) : null}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              boxSizing: "border-box",
              gap: "20px 20px",
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
              <Box sx={{ borderTop: "2px solid #F2F2F2", py: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1.5rem" },
                      color: "#000",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                    }}
                  >
                    Products
                  </Typography>
                  <Typography
                    sx={{
                      ml: 1,
                      fontSize: { xs: "0.9rem", md: "1.5rem" },
                      color: "#698DB1",
                      fontFamily: "Open Sans",
                      fontWeight: "700",
                    }}
                  >
                    ({data?.data?.length ?? 0})
                  </Typography>
                </Box>

                {/* // changes table to box */}
                <Box
                  mt={4}
                  sx={{
                    width: "100%",
                    min: { xs: "370px", md: "300px" },
                    maxHeight: "310px",
                    overflow: "scroll",

                    "&::-webkit-scrollbar": {
                      width: "0 !important",
                    },
                    "&::-webkit-scrollbar:horizontal": {
                      height: "2px !important",
                    },
                    "&::-webkit-scrollbar:verticle": {
                      width: "2px !important",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#ffffff",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#2CEA6A",
                    },
                  }}
                >
                  {data?.data?.map(({ productDetail, imagesArray }, i) => {
                    return (
                      <Accordion
                        key={i}
                        sx={{
                          borderBottom: "none",
                          borderTop: "none",
                          boxShadow: "none",
                          "&.MuiAccordion-root:before ": {
                            height: "0px !important",
                          },
                          width: "100%",
                          my: 1,
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                fontSize: "1.2rem",
                                background: " #BDBEC1",
                                borderRadius: "50%",
                                color: "#ffff",
                                fontWeight: "700",
                              }}
                            />
                          }
                          id="panel1a-header"
                          onClick={handlechange}
                          sx={(theme) => ({
                            border: "2px solid rgba(220, 220, 220, 0.69)",
                            background: theme.palette.background.offWhite,
                            borderRadius: "8px",
                            height: "80px",
                            width: matches ? "800px" : "100%",
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "0px 10px",

                            // border: "2px solid blue",
                          })}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              //  justifyContent:"space-between",
                              gap: "0px 30px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                width: "35%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "0px 10px",
                              }}
                            >
                              {/* <ExpandMoreIcon /> */}
                              <Box
                                sx={{
                                  width: "100px",
                                  height: "60px",
                                }}
                              >
                                <img
                                  src={
                                    productDetail?.images?.length > 0
                                      ? `${productDetail?.images[0]}`
                                      : "https://pbs.twimg.com/media/FhC3LvHXkAEMEUZ.png"
                                  }
                                  alt=""
                                  srcSet=""
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                />
                              </Box>
                              <Box maxWidth="200px">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "0px 10px",
                                  }}
                                >
                                  {/* <Typography2>
                                    {productDetail?._id?.slice(-8)}
                                  </Typography2> */}
                                  {/* <Typography2>
                                    {productDetail?.featured ? "Features" : ""}{" "}
                                  </Typography2> */}
                                </Box>
                                <Typography2
                                  style={{
                                    fontSize: "16px",
                                    color: "#000",
                                  }}
                                >
                                  {productDetail?.name}
                                </Typography2>
                              </Box>
                            </Box>

                            <Box sx={{ width: "30%" }}>
                              <Typography2 style={{ fontSize: "16px" }}>
                                ${productDetail?.price}
                              </Typography2>
                              {/* <Typography2 style={{ fontSize: "16px" }}>
                                {productDetail?.unLimitedStock === true
                                  ? "Unlimted Items"
                                  : productDetail?.totalQuantity}{" "}
                                in stock
                              </Typography2> */}
                            </Box>

                            <Box sx={{ width: "30%" }}>
                              <Typography2 style={{ fontSize: "16px" }}>
                                {productDetail?.productType}
                              </Typography2>
                              <Typography2 style={{ fontSize: "16px" }}>
                                {productDetail?.heatPress
                                  ? "Heatpress"
                                  : productDetail?.embossing
                                  ? "Embossing"
                                  : productDetail?.custom
                                  ? "Custom"
                                  : "N/A"}
                              </Typography2>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            border: "2px solid rgba(220, 220, 220, 0.69)",
                            mt: 1,
                            borderRadius: "8px",
                            boxShadow: "none",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: {
                                xs: "flex-start",
                                md: "center",
                              },
                              width: "100%",
                            }}
                          >
                            {imagesArray?.images.length > 0 ? (
                              imagesArray?.images?.map((imgUrl, i) => (
                                <Box
                                  key={i * Math.random() + i}
                                  sx={{
                                    width: "200px",
                                    position: "relative",
                                    p: 1,
                                  }}
                                >
                                  <Box
                                    onClick={(e) => {
                                      downloadImage(`${imgUrl}`);
                                    }}
                                    sx={{
                                      position: "absolute",
                                      bottom: "0px",
                                      right: "0px",
                                    }}
                                  >
                                    <img
                                      src={downloadbtn}
                                      width="40px"
                                      alt="lock"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    textAlign="center"
                                    color="#000"
                                    fontSize="14px !important"
                                  >
                                    {i === 0
                                      ? "Front image"
                                      : i === 1
                                      ? "Back image"
                                      : ""}
                                  </Typography>
                                  <img
                                    src={
                                      productDetail?.images?.length > 0
                                        ? `${imgUrl}`
                                        : "https://pbs.twimg.com/media/FhC3LvHXkAEMEUZ.png"
                                    }
                                    alt=""
                                    srcSet=""
                                    style={{
                                      width: "150px",
                                      height: "120px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </Box>
                              ))
                            ) : (
                              <Typography color="#000">
                                No Images Found
                              </Typography>
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}

                  {/* <Box
                      sx={{
                        background: "#F6F6F6",
                        borderRadius: "14px",
                        height: "289px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h1">msg</Typography>
                    </Box> */}
                </Box>
              </Box>
              {/* {data?.orderDetail?.images.length > 0 ? (
                <Box sx={{ borderTop: "1px solid #F2F2F2", py: 5 }}>
                  <Typography variant="h5">Custmaization</Typography>
                  <Box
                    display="flex"
                    width={matches ? "100%" : "50%"}
                    mt={matches ? 1 : 2}
                  >
                    {data?.orderDetail?.images?.map((path, i) => (
                      <img
                        key={i}
                        src={`${path}`}
                        style={{ width: "140px", marginRight: "10px" }}
                        alt="img"
                      />
                    ))}
                  </Box>
                </Box>
              ) : null} */}

              <Box sx={{ borderTop: "1px solid #F2F2F2", py: 5 }}>
                <Box sx={{ width: "100%" }}>
                  <Typography variant="h5" mt={5}>
                    PAYMENT
                  </Typography>
                  <Box display="flex" width="100%" mt={matches ? 1 : 2}>
                    <Typography
                      variant="subtypo2"
                      sx={{ width: { xs: "50%", sm: "30%" } }}
                    >
                      Payment Method
                    </Typography>
                    <Typography
                      variant="subtypo"
                      sx={{
                        width: {
                          xs: "50%",
                          sm: "70%",
                          overflowWrap: "break-word",
                        },
                      }}
                    >
                      {data?.orderDetail?.payWith}
                    </Typography>
                  </Box>
                  {/* {data?.orderDetail?.payWith === "Crypto" ? null : (
                    <>
                      <Box display="flex" width="100%" mt={matches ? 1 : 2}>
                        <Typography
                          variant="subtypo2"
                          sx={{ width: { xs: "50%", sm: "30%" } }}
                        >
                          Payment Details
                        </Typography>
                        <Typography
                          variant="subtypo"
                          sx={{
                            width: {
                              xs: "50%",
                              sm: "70%",
                              overflowWrap: "break-word",
                            },
                          }}
                        >
                          {data?.orderDetail?.firstName}{" "}
                          {data?.orderDetail?.lastName}
                          <br />
                          ********{data?.orderDetail?.cardLastFour}
                          <br />
                          EXP: {data?.orderDetail?.cardExpDate}
                        </Typography>
                      </Box>
                    </>
                  )} */}
                </Box>
                <Box
                  my={4}
                  sx={{ width: "100%", border: "2px solid #F7F7F7" }}
                />
                <Box sx={{ width: "100%" }}>
                  <Typography variant="h5" mt={5}>
                    Shipping
                  </Typography>
                  <Box
                    display="flex"
                    width="100%"
                    mt={matches ? 1 : 2}

                    // gap={matches ? 7 : 10.5}
                  >
                    <Typography
                      variant="subtypo2"
                      sx={{ width: { xs: "50%", sm: "30%" } }}
                    >
                      Shipping Method
                    </Typography>
                    <Typography
                      variant="subtypo"
                      sx={{ width: { xs: "50%", sm: "70%" } }}
                    >
                      {data?.orderDetail?.shippingMethod === "19.99"
                        ? "USA and Canada"
                        : data?.orderDetail?.shippingMethod === "100"
                        ? "World Wide"
                        : data?.orderDetail?.shippingMethod === "200"
                        ? "China"
                        : data?.orderDetail?.shippingMethod === "1"
                        ? "Bahrain"
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    width="100%"
                    mt={matches ? 1 : 2}
                    // gap={matches ? 12.4 : 16}
                  >
                    <Typography
                      variant="subtypo2"
                      sx={{ width: { xs: "50%", sm: "30%" } }}
                    >
                      User Email
                    </Typography>
                    <Typography
                      variant="subtypo"
                      sx={{
                        width: {
                          xs: "50%",
                          sm: "70%",
                          overflowWrap: "break-word",
                        },
                      }}
                    >
                      {data?.orderDetail?.email}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    width="100%"
                    mt={matches ? 1 : 2}

                    // gap={matches ? 7.7 : 11.3}
                  >
                    <Typography
                      variant="subtypo2"
                      sx={{ width: { xs: "50%", sm: "30%" } }}
                    >
                      Shipping Details
                    </Typography>
                    <Typography
                      variant="subtypo"
                      sx={{
                        width: {
                          xs: "50%",
                          sm: "70%",
                          overflowWrap: "break-word",
                        },
                      }}
                    >
                      Mr: {data?.orderDetail?.firstName}{" "}
                      {data?.orderDetail?.lastName}
                      <br />
                      Country: {data?.orderDetail?.country}
                      <br />
                      Address: {data?.orderDetail?.address}
                      <br />
                      {data?.orderDetail?.city ? (
                        <>
                          City: {data?.orderDetail?.city} <br />
                        </>
                      ) : null}
                      {data?.orderDetail?.province ? (
                        <>
                          State: {data?.orderDetail?.province} <br />
                        </>
                      ) : null}
                      Contact No: {data?.orderDetail?.contactNo}
                      <br />
                      Postal Code: {data?.orderDetail?.postalCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "80%", md: "30%" },
                mx: { xs: "auto", md: "0px" },
                display: "flex",
                flexDirection: "column",
                gap: "20px 0px",
              }}
            >
              <OrderSumarry
                props={{
                  data: [
                    {
                      name: "Subtotal",
                      value: `${data?.orderDetail?.subTotal}`,
                    },
                    {
                      name: "Discount",
                      value: `-$ ${data?.orderDetail?.discountAmount}`,
                    },
                    {
                      name: "Applied Discount Code",
                      value: `${
                        data?.orderDetail?.discountCode
                          ? data?.orderDetail?.discountCode
                          : "N/A"
                      }`,
                    },
                    {
                      name: "Shipping",
                      value: `$ ${data?.orderDetail?.shippingMethod}`,
                    },
                    { name: "Estimated Taxes", value: "-$ 0" },
                  ],
                  totalAmount: data?.orderDetail?.amount,
                }}
              />
              {/* <ByingInfo
                props={[
                  { name: "Subtotal", value: `${data?.orderDetail?.subTotal}` },
                  {
                    name: "Discount",
                    value: `-$ ${data?.orderDetail?.discountAmount}`,
                  },
                  {
                    name: "Applied Discount Code",
                    value: `${data?.orderDetail?.discountCode}`,
                  },
                  { name: "Shipping", value: "$ 15.00" },
                  { name: "Estimated Taxes", value: "-$ 9.99" },
                ]}
              /> */}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};
