import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        sx={{
          height: "35px",
          width: "100%",

          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "8px",
          color: "rgba(18, 21, 32, 0.69)",
          "&:hover": {
            border: " 1px solid #D3EFF6",
            background:
              "linear-gradient(231.13deg, rgba(8, 165, 207, 0.18) 14.71%, rgba(47, 239, 100, 0.18) 87.56%)",
          },
          ".MuiButton-outlined": {
            border: "none",
          },
        }}
      >
        <KeyboardBackspace
          style={{
            marginRight: "10px",
            fontSize: "23px",
            color: " rgba(18, 21, 32, 0.69)",
          }}
        />
        Back
      </Button>
    </>
  );
};


