import React, { useState } from "react";
import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";

// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import { Link, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { IOSSwitch } from "../Components/SmallComponents/AppComponents";

import {
  useDeleteDiscountMutation,
  useActiveDiscountMutation,
} from "../store/dicountApi";
import Loading from "../Components/SmallComponents/loading";
import { useDispatch } from "react-redux";
import { openDeleteModal, openErrorModal } from "../store/componentState";

export default function DiscountList({ data }) {
  //   const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:960px)");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [deleteDiscountApi] = useDeleteDiscountMutation();
  const [activeProductApi] = useActiveDiscountMutation();

  const handleSwitch = async (e, id) => {
    setLoading(true);
    try {
      const { error } = await activeProductApi({
        id: id,
        status: e.target.checked,
      });
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const deleteDiscount = async (id) => {
    try {
      setLoading(true);
      const { error } = await deleteDiscountApi(id);
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      dispatch(
        openDeleteModal({
          open: false,
          deleteFunction: "",
          id: "",
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <Box
        mt={6}
        sx={{
          width: "100%",
          height: "300px",
          overflowX: "scroll",

          "&::-webkit-scrollbar": {
            width: "0 !important",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "2px !important",
          },
          "&::-webkit-scrollbar:verticle": {
            width: "2px !important",
          },
          "&::-webkit-scrollbar-track": {
            background: "#ffffff",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#2CEA6A",
          },
        }}
      >
        {data?.data?.length > 0 ? (
          data?.data?.map(
            (
              {
                _id,
                discountCode,
                discount,
                discountExpDate,
                activeDiscount,
                disableDiscount,
              },
              i
            ) => {
              return (
                <Box
                  mb={2}
                  key={i}
                  sx={{
                    height: "80px",
                    width: matches ? "1140px" : "100%",
                    borderRadius: "14px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#F7F7F7, #F7F7F7  ),linear-gradient(37.34deg, #f7f7f7 4.36%, #f7f7f7  48.4%, #f7f7f7  96.11%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#F7F7F7, #F7F7F7  ),linear-gradient(269.3deg, #2CEA6A 4.73%, #08A7CC 88.73%)",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                  >
                    <Typography variant="h3" width="20%">
                      {discountCode}
                    </Typography>
                    <Typography variant="h3" width="20%">
                      {`discount ${discount}%`}
                    </Typography>
                    <Typography variant="h3" width="20%">
                      {`Exp. date ${moment
                        .unix(discountExpDate)
                        .format("DD/MM/YYYY")}`}
                    </Typography>

                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                      width="20%"
                    >
                      <Typography variant="h3" textAlign="flex-end">
                        {activeDiscount
                          ? "Active"
                          : disableDiscount
                          ? "Expired"
                          : "Not Active"}
                      </Typography>
                      <IOSSwitch
                        disabled={disableDiscount ?? false}
                        checked={activeDiscount ?? false}
                        onChange={(e) => handleSwitch(e, _id)}
                      />
                    </Box>
                    <Box display="flex" width="20%" justifyContent="flex-end">
                      <Box
                        onClick={() =>
                          dispatch(
                            openDeleteModal({
                              open: true,
                              deleteFunction: deleteDiscount,
                              id: _id,
                            })
                          )
                        }
                        mr="3px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "#FFFFFF",
                          border: "1px solid #DCDCDC",
                          borderRadius: "5px",
                          width: "44px",
                          height: "44px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Delete">
                          <Delete
                            sx={{
                              color: "#EF5656",
                            }}
                          />
                        </Tooltip>
                      </Box>
                      {/* <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          // onClick={() => navigate(`/web2saveproduct/${i}`)}
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #DCDCDC",
                            borderRadius: "5px",
                            width: "44px",
                            height: "44px",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title="View Detail">
                            <ArrowForwardIcon
                              sx={{ color: "rgba(18, 21, 32, 0.69)" }}
                            />
                          </Tooltip>
                        </Box> */}
                    </Box>
                  </Box>
                </Box>
              );
            }
          )
        ) : (
          <Box
            sx={{
              background: "#F6F6F6",
              borderRadius: "14px",
              height: "289px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">No discounts added yet</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
