import { Box, Typography } from "@mui/material";
import React from "react";

export const OrderSumarry = ({ props }) => {
  return (
    <Box
      sx={{
        width: "100%",
        border: "2px solid rgba(220, 220, 220, 0.69)",
        p: 2,
        borderRadius: "10px",
      }}
    >
      <Typography variant="subtypo" sx={{ fontWeight: "600" }}>
        Order Summary
      </Typography>

      {props?.data?.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 1.4,
            }}
          >
            <Typography variant="subtypo">{item.name}</Typography>
            <Typography variant="subtypo" sx={{ fontWeight: "600" }}>
              {" "}
              {item.value}
            </Typography>
          </Box>
        );
      })}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2.4,
        }}
      >
        <Typography variant="subtypo" sx={{ fontWeight: "600" }}>
          Total
        </Typography>
        <Typography variant="subtypo" sx={{ fontWeight: "600" }}>
          {" "}
          $ {props?.totalAmount}
        </Typography>
      </Box>
    </Box>
  );
};
