import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { StyledButton } from "../Components/SmallComponents/AppComponents";
import Loading from "../Components/SmallComponents/loading";
import { Delete } from "@mui/icons-material";
import {
  useGetSingleWeb2ProductQuery,
  useDeleteSingleWeb2ProductMutation,
  usePublishWeb2ProductMutation,
} from "../store/web2ProductApi";
import { Link, useParams, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { openDeleteModal, openErrorModal } from "../store/componentState";
import { BackButton } from "../Components/SmallComponents/BackButton";

export default function Web2SaveProduct() {
  const params = useParams();

  const [publishedProduct, setPublishedProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    data = [],
    isError,
    isLoading,
  } = useGetSingleWeb2ProductQuery(params?.id);
  const dispatch = useDispatch();
  const [deleteProductApi] = useDeleteSingleWeb2ProductMutation();
  const [publishedWeb2ProductApi] = usePublishWeb2ProductMutation();

  const matches = useMediaQuery("(max-width:960px)");
  const [productData, setProductData] = useState({});
  const navigate = useNavigate();

  const publishedWeb3Product = async () => {
    try {
      setLoading(true);
      const { data, error } = await publishedWeb2ProductApi({
        id: params?.id,
        status: !publishedProduct,
      });
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      setPublishedProduct(data?.status);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const deleteProduct = async (id) => {
    setLoading(true);
    const { error } = await deleteProductApi(id);
    if (error) {
      setLoading(false);
      return dispatch(
        openErrorModal({
          open: true,
          errorMessage: error?.data?.message,
        })
      );
    }
    dispatch(
      openDeleteModal({
        open: false,
        deleteFunction: "",
        id: "",
      })
    );
    setLoading(false);
    navigate(-1);
    try {
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isError) {
      return console.log(isError);
    }
    setProductData(data?.data);
    setPublishedProduct(data?.data?.published ?? false);
  }, [data, isError]);
  return (
    <>
      <Loading loading={loading} />
      <Loading loading={isLoading} />
      <Box mt={10}>
        <Container maxWidth="lg">
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            mt={2}
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              sx={{
                maxWidth: { xs: "300px", md: "400px" },
                fontSize: { xs: "20px", md: "30px" },
                fontWeight: "700",
                fontFamily: "Open Sans",
                color: "black",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                mr: 1,
                mb: 1,
              }}
            >
              {productData?.name}
            </Typography>

            <Box display="flex">
              <Link
                to={`/editweb2product/${params?.id}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  mr="6px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "#FFFFFF",
                    border: "1px solid #DCDCDC",
                    borderRadius: "10px",
                    width: "44px",
                    height: "44px",
                  }}
                >
                  <Tooltip title="Edit">
                    <EditIcon sx={{ color: "rgba(18, 21, 32, 0.69)" }} />
                  </Tooltip>
                </Box>
              </Link>

              <Box
                mr="6px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={() =>
                  dispatch(
                    openDeleteModal({
                      open: true,
                      deleteFunction: deleteProduct,
                      id: params?.id,
                    })
                  )
                }
                sx={{
                  background: "#FFFFFF",
                  border: "1px solid #DCDCDC",
                  borderRadius: "10px",
                  width: "44px",
                  height: "44px",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Delete">
                  <Delete
                    sx={{
                      color: "#EF5656",
                    }}
                  />
                </Tooltip>
              </Box>
              <StyledButton width="170px" onClick={publishedWeb3Product}>
                {publishedProduct ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      mr={1}
                      sx={{
                        width: "21px",
                        height: "21px",
                        background:
                          "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckIcon sx={{ color: "#fff", fontSize: "20px" }} />
                    </Box>
                    PUBLISHED
                  </Box>
                ) : (
                  <>
                    <CancelIcon
                      sx={{ color: "red", fontSize: "20px", mr: 1 }}
                    />
                    Un PUBLISHED
                  </>
                )}
              </StyledButton>
            </Box>
          </Box>
          <Box my={4} sx={{ width: "100%", border: "2px solid #f7f7f7" }} />
          <Box display="flex" flexDirection={matches ? "column" : "row"}>
            <Typography variant="subtypo2" mr={matches ? 0 : 8}>
              Product Name
            </Typography>
            <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
              {productData?.name}
            </Typography>
          </Box>
          <Box display="flex" flexDirection={matches ? "column" : "row"} mt={2}>
            <Typography variant="subtypo2" mr={matches ? 0 : 16}>
              Images
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {productData?.images?.map((info) => (
                <img
                  key={info}
                  src={`${info}`}
                  style={{
                    marginTop: matches ? "10px" : "0px",
                    marginRight: "20px",
                    width: "144px",
                    height: "100px",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              ))}
            </Box>
          </Box>
          <Box display="flex" flexDirection={matches ? "column" : "row"} mt={2}>
            <Typography variant="subtypo2" mr={matches ? 0 : 12}>
              Description
            </Typography>
            <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
              {productData?.description}
            </Typography>
          </Box>
          <Box my={4} sx={{ width: "100%", border: "2px solid #f7f7f7" }} />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={matches ? "column" : "row"}
            mt={2}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="subtypo2" mr={matches ? 4.5 : 6}>
                Quantity in Stock
              </Typography>
              <Typography variant="subtypo">
                {productData?.unLimitedStock === true
                  ? "Unlimted Stock"
                  : productData?.totalQuantity}
              </Typography>
            </Box>
            <Box
              display="flex"
              width={matches ? "100%" : "50%"}
              mt={matches ? 1 : 0}
            >
              <Typography variant="subtypo2" mr={matches ? 7 : 5}>
                Customization
              </Typography>
              <Typography variant="subtypo">
                {productData?.embossing
                  ? "Embossing"
                  : productData?.heatPress
                  ? `Heatpress (${productData?.heatPressType ?? "N/A"})`
                  : productData?.custom
                  ? `Custom (${productData?.customType ?? "N/A"})`
                  : "N/A"}
              </Typography>
            </Box>

            {/* <Box
              display="flex"
              width={matches ? "100%" : "50%"}
              mt={matches ? 1 : 0}
            >
              <Typography variant="subtypo2" mr={matches ? 9.7 : 8}>
                Discount %{" "}
              </Typography>
              <Typography variant="subtypo">
                {productData?.discount != null
                  ? `${productData?.discount}%`
                  : "N/A"}
              </Typography>
            </Box> */}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={matches ? "column" : "row"}
            mt={matches ? 1 : 4}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="subtypo2" mr={matches ? 9.4 : 11}>
                Pricing Unit
              </Typography>
              <Typography variant="subtypo">$ USD</Typography>
            </Box>
            <Box
              display="flex"
              width={matches ? "100%" : "50%"}
              mt={matches ? 1 : 0}
            >
              <Typography variant="subtypo2" mr={matches ? 11 : 9}>
                Unit Price
              </Typography>
              <Typography variant="subtypo">$ {productData?.price}</Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
