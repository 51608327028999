import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Edit, ArrowForward } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import {
  useDeleteSingleCollectionMutation,
  useFeaturedCollectionMutation,
} from "../store/collectionApi";

import { IOSSwitch } from "../Components/SmallComponents/AppComponents";
import Loading from "../Components/SmallComponents/loading";
import { useDispatch } from "react-redux";
import { openDeleteModal, openErrorModal } from "../store/componentState";

function CollectionList({ data, message }) {
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(max-width:960px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const [removeCollectionApi] = useDeleteSingleCollectionMutation();
  const [featuredCollectionApi] = useFeaturedCollectionMutation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const removeCollection = async (id) => {
    try {
      setLoading(true);
      const { error } = await removeCollectionApi(id);
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      dispatch(
        openDeleteModal({
          open: false,
          deleteFunction: "",
          id: "",
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleSwitch = async (e, id) => {
    setLoading(true);
    try {
      const { error } = await featuredCollectionApi({
        id: id,
        status: e.target.checked,
      });
      if (error) {
        setLoading(false);
        console.log(error);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <Box
        mt={4}
        sx={{
          width: "100%",
          height: { xs: "370px", md: "300px" },
          overflow: "scroll",

          "&::-webkit-scrollbar": {
            width: "0 !important",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "2px !important",
          },
          "&::-webkit-scrollbar:verticle": {
            width: "2px !important",
          },
          "&::-webkit-scrollbar-track": {
            background: "#ffffff",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#2CEA6A",
          },
        }}
      >
        {data?.length > 0 ? (
          data?.map(
            (
              { _id, name, logo, featured, products, createdAt, updatedAt },
              i
            ) => {
              return (
                <Box
                  mb={2}
                  key={i}
                  sx={{
                    height: "80px",
                    width: matches ? "1140px" : "100%",
                    borderRadius: "14px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#F7F7F7, #F7F7F7  ),linear-gradient(37.34deg, #f7f7f7 4.36%, #f7f7f7  48.4%, #f7f7f7  96.11%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#F7F7F7, #F7F7F7  ),linear-gradient(269.3deg, #2CEA6A 4.73%, #08A7CC 88.73%)",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                  >
                    <Box display="flex" alignItems="center" width="65%">
                      <Box>
                        <img
                          src={`${logo}`}
                          width="86px"
                          height="60px"
                          style={{ borderRadius: "8px", objectFit: "cover" }}
                          alt=""
                        />
                      </Box>

                      <Typography
                        variant="h3"
                        ml={1}
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {name}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      // width="170px"
                      justifyContent="space-around"
                      alignItems="center"
                      width="15%"
                    >
                      <Typography variant="h3">
                        {featured ? "Featured" : "Not Featured"}
                      </Typography>
                      <IOSSwitch
                        checked={featured ?? false}
                        onChange={(e) => handleSwitch(e, _id)}
                      />
                    </Box>
                    <Box display="flex" width="15%" justifyContent="flex-end">
                      <Link
                        to={`/editcollection/${_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          onClick={handleClick}
                          mr="3px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #DCDCDC",
                            borderRadius: "5px",
                            width: "44px",
                            height: "44px",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title="Edit">
                            <Edit
                              sx={{
                                color: "rgba(18, 21, 32, 0.69)",
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Link>
                      <Box
                        onClick={() =>
                          dispatch(
                            openDeleteModal({
                              open: true,
                              deleteFunction: removeCollection,
                              id: _id,
                            })
                          )
                        }
                        mr="3px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        sx={{
                          background: "#FFFFFF",
                          border: "1px solid #DCDCDC",
                          borderRadius: "5px",
                          width: "44px",
                          height: "44px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Delete">
                          <Delete
                            sx={{
                              color: "#EF5656",
                            }}
                          />
                        </Tooltip>
                      </Box>
                      {/* <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  background: "#ffffff",
                                  width: "120px",
                                  filter:
                                    "drop-shadow(0px 2px 2px rgba(0,0,0,0.12))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "#fff",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              {/* //add a router dom dynamic  link and pass id params */}

                      <Link to={`/savecollection/${_id}`}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #DCDCDC",
                            borderRadius: "5px",
                            width: "44px",
                            height: "44px",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              background: "#FFFFFF",
                              border: "1px solid #DCDCDC",
                              borderRadius: "5px",
                              width: "44px",
                              height: "44px",
                            }}
                          >
                            <Tooltip title=" view Detail">
                              <ArrowForward
                                sx={{ color: "rgba(18, 21, 32, 0.69)" }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )
        ) : (
          <Box
            sx={{
              background: "#F6F6F6",
              borderRadius: "14px",
              height: "289px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">{message}</Typography>
          </Box>
        )}
      </Box>
      {/* <Box display="flex" justifyContent="center" mt={4}>
        <Box
          sx={{
            cursor: "pointer",
            border: "3px solid rgba(82, 89, 96, 0.26)",
            width: "232px",
            height: "61px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "18px",
            fontFamily: "Open Sans",
            fontSize: "18px",
            fontWeight: "400",
            color: "rgba(18, 21, 32, 0.69)",
          }}
        >
          VIEW ALL
          <ArrowForward
            style={{
              marginLeft: "10px",
              fontSize: "23px",
              color: "rgba(18, 21, 32, 0.69)",
            }}
          />
        </Box>
      </Box> */}
    </>
  );
}

export default CollectionList;
