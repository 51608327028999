import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CollectionList from "./CollectionList";

import { useGetAllCollectionQuery } from "../store/collectionApi";
import StyledTabs from "./StyledTabs";

import Loading from "../Components/SmallComponents/loading";

const btnName = ["PUBLISHED", "UNPUBLISHED"];

export default function Collections() {
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(max-width:960px)");
  const [tabsValue, setTabsValue] = useState(0);

  const {
    data = [],
    isError,
    isSuccess,
    isLoading,
  } = useGetAllCollectionQuery();

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
    }
    if (isError) {
      setLoading(false);
      return console.log(isError);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <Loading loading={loading} />
      <Loading loading={isLoading} />

      <Box sx={{pt:{xs:5,md:10}}}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h1" mb={matches ? 2 : 0}>
              Collections ({data?.totalCollection ?? 0})
            </Typography>
            <Link to="/createcollection" style={{ textDecoration: "none" }}>
              <Button variant="gradient" sx={{ width: "260px" }}>
                CREATE COLLECTION
              </Button>
            </Link>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box mb={matches ? 2 : 0}>
              <StyledTabs
                btnName={btnName}
                setTabsValue={setTabsValue}
                tabsValue={tabsValue}
              />
            </Box>
          </Box>

          {tabsValue === 0 ? (
            <CollectionList
              data={data.publisedCollection ?? []}
              message={"All collections are un-published!"}
            />
          ) : (
            <CollectionList
              data={data.unPublisedCollection ?? []}
              message={"All collections are published!"}
            />
          )}
        </Container>
      </Box>
    </>
  );
}
