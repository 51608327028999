import { api } from "./api";

export const discountApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    addDiscount: builder.mutation({
      query: (discountData) => {
        return {
          url: "/add-discount",
          method: "POST",
          body: discountData,
        };
      },
      invalidatesTags: ["getAllDisounts"],
    }),
    getAllDiscount: builder.query({
      query: () => {
        return {
          url: `/get-all-discounts`,
          method: "GET",
        };
      },
      providesTags: ["getAllDisounts"],
    }),
    deleteDiscount: builder.mutation({
      query: (discountId) => {
        return {
          url: `/delete-single-discount/${discountId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["getAllDisounts"],
    }),
    activeDiscount: builder.mutation({
      query: (updatePayload) => {
        const { id, status } = updatePayload;
        return {
          url: `/active-discount/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["getAllDisounts"],
    }),
  }),
});

export const {
  useAddDiscountMutation,
  useGetAllDiscountQuery,
  useDeleteDiscountMutation,
  useActiveDiscountMutation,
} = discountApi;
