import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import CustomCencelBtn from "./cutomCencelBtn";
import { useSelector, useDispatch } from "react-redux";
import { openErrorModal } from "../../store/componentState";
import { errorIcon } from "./Images";
export default function ErrorModal() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.componentState.errorState);

  const handleClose = () => {
    dispatch(openErrorModal({ open: false, errorMessage: "" }));
  };

  return (
    <>

      <Dialog
        open={state.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: 100,
          "& .MuiPaper-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            width: { xs: "100%", sm: "60%", md: "40%" },
            border: "1px solid #8C9296",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: "100px", height: "100px", mx: "auto" }}>
            <img
              src={errorIcon}
              alt=""
              srcSet=""
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: "20px",
            }}
          >
            Error!
          </DialogTitle>

          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Open Sans",
              color: "rgba(18, 27, 37, 0.69)",
              fontSize: "11px",
              width: "90%",
              mx: "auto",
              my: 1,
            }}
          >
            {state.errorMessage}
          </Typography>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width="150px" onClick={handleClose}>
              <CustomCencelBtn cencel={"cancel"} />
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
