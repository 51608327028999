export const handleKeyDown = (event) => {
  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    event.preventDefault();
  }
};

export const handleWheel = (event) => {
  event.currentTarget.blur();
};

export const actualImgSize = 2 * 1024 * 1024;
