import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",

    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    paddingLeft: "40px",
    paddingRight: "40px",
    color: "#7A7C82",

    fontWeight: "bold",
    fontFamily: "Open Sans",
    "&:hover": {
      color: "#7A7C82",
    },
    "&.Mui-selected": {
      color: "white",
      background: "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
      opacity: 1,
      borderRadius: "5px",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#7A7C82",
    },
  })
);

export default function StyledTabs({ btnName, setTabsValue, tabsValue }) {
  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };
  return (
    <>
      <Box
        sx={{
          border: "2px solid #D2D4D6",
          borderRadius: "10px",
          width: "fit-content",
        }}
      >
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={"scrollable"}
          scrollButtons={"auto"}
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {btnName?.map((name, index) => (
            <AntTab key={name} label={name} value={index} />
          ))}
        </Tabs>
      </Box>
    </>
  );
}
