import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import { StyledButton } from "../Components/SmallComponents/AppComponents";
import {
  useGetSingleCollectionQuery,
  usePublishCollectionMutation,
  useDeleteSingleCollectionMutation,
} from "../store/collectionApi";
import ProductList from "./ProductList";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import StyledTabs from "./StyledTabs";
import Loading from "../Components/SmallComponents/loading";
import { useDispatch } from "react-redux";
import {
  collectionStateHandle,
  collectionProductDeleteHandle,
} from "../store/componentState";
import { openDeleteModal, openErrorModal } from "../store/componentState";
import { BackButton } from "../Components/SmallComponents/BackButton";
import {
  addCollectionDetail,
  addCollectionProductDeleteDetail,
} from "../services/localStorage";
const btnName = ["PUBLISHED", "UNPUBLISHED"];
export default function SaveCollection() {
  const matches = useMediaQuery("(max-width:960px)");
  const [tabsValue, setTabsValue] = useState(0);
  const params = useParams();
  const { data, isError, isLoading } = useGetSingleCollectionQuery(params?.id);
  const [collectionData, setCollectionData] = useState({});
  const [publishedCollection, setPublishedCollection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishCollectionApi] = usePublishCollectionMutation();
  const [removeCollectionApi] = useDeleteSingleCollectionMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publishCollection = async () => {
    try {
      setLoading(true);
      const { data, error } = await publishCollectionApi({
        id: params?.id,
        status: !publishedCollection,
      });
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      setPublishedCollection(data?.status);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const removeCollection = async (id) => {
    try {
      setLoading(true);
      const { error } = await removeCollectionApi(id);
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      dispatch(
        openDeleteModal({
          open: false,
          deleteFunction: "",
          id: "",
        })
      );
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isError) {
      return console.log(isError);
    }
    setCollectionData(data?.data);
    setPublishedCollection(data?.data?.published ?? false);
    dispatch(
      collectionStateHandle({
        collectionName: data?.data?.name ?? "",
        collectionId: data?.data?._id ?? "",
      })
    );
    addCollectionDetail(
      JSON.stringify({
        collectionName: data?.data?.name ?? "",
        collectionId: data?.data?._id ?? "",
      })
    );
    dispatch(collectionProductDeleteHandle(data?.data?._id ?? ""));
    addCollectionProductDeleteDetail(data?.data?._id ?? "");
  }, [data?.data, dispatch, isError]);

  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={loading} />
      <Box pt={5}>
        <Container maxWidth="lg">
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            mt={2}
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  maxWidth: { xs: "300px", md: "400px" },
                  fontSize: { xs: "20px", md: "30px" },
                  fontWeight: "700",
                  fontFamily: "Open Sans",
                  color: "black",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  mr: 1,
                  mb: 1,
                }}
              >
                {collectionData?.name}
              </Typography>
              {matches
                ? ""
                : collectionData?.featured && (
                    <StyledButton width={"200px"} height={"50px"}>
                      FEATURED COLLECTION
                    </StyledButton>
                  )}
            </Box>

            <Box display="flex">
              <Link
                to={`/editcollection/${params?.id}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  mr="6px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "#FFFFFF",
                    border: "1px solid #DCDCDC",
                    borderRadius: "10px",
                    width: "44px",
                    height: "44px",
                  }}
                >
                  <Tooltip title="Edit">
                    <EditIcon sx={{ color: "rgba(18, 21, 32, 0.69)" }} />
                  </Tooltip>
                </Box>
              </Link>
              <Box
                onClick={() =>
                  dispatch(
                    openDeleteModal({
                      open: true,
                      deleteFunction: removeCollection,
                      id: params?.id,
                    })
                  )
                }
                mr="6px"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  background: "#FFFFFF",
                  border: "1px solid #DCDCDC",
                  borderRadius: "10px",
                  width: "44px",
                  height: "44px",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Delete">
                  <Delete
                    sx={{
                      color: "#EF5656",
                    }}
                  />
                </Tooltip>
              </Box>
              <StyledButton width="170px" onClick={publishCollection}>
                {publishedCollection ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      mr={1}
                      sx={{
                        width: "21px",
                        height: "21px",
                        background:
                          "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckIcon sx={{ color: "#fff", fontSize: "20px" }} />
                    </Box>
                    PUBLISHED
                  </Box>
                ) : (
                  <>
                    <CancelIcon
                      sx={{ color: "red", fontSize: "20px", mr: 1 }}
                    />
                    Un PUBLISHED
                  </>
                )}
              </StyledButton>
            </Box>
          </Box>
          <Box my={4} sx={{ width: "100%", border: "2px solid #f7f7f7" }} />
          <Box display="flex" flexDirection={matches ? "column" : "row"}>
            <Typography variant="subtypo2" mr={matches ? 0 : 8}>
              Collection Name
            </Typography>
            <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
              {collectionData?.name}
            </Typography>
          </Box>
          <Box display="flex" flexDirection={matches ? "column" : "row"} mt={2}>
            <Typography variant="subtypo2" mr={matches ? 0 : 17}>
              Image
            </Typography>
            <img
              src={`${collectionData?.logo}`}
              width="144px"
              style={{
                marginTop: matches ? "10px" : "0px",
                objectFit: "cover",
                borderRadius: "5px",
              }}
              alt=""
            />
          </Box>
          <Box display="flex" flexDirection={matches ? "column" : "row"} mt={2}>
            <Typography variant="subtypo2" mr={matches ? 0 : 12}>
              Description
            </Typography>
            <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
              {collectionData?.description}
            </Typography>
          </Box>
          <Box my={4} sx={{ width: "100%", border: "2px solid #f7f7f7" }} />
          <Box display="flex" flexDirection={matches ? "column" : "row"}>
            <Typography variant="subtypo2" mr={matches ? 0 : 12}>
              Twitter link
            </Typography>
            <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
              {collectionData?.twitterLink}
            </Typography>
          </Box>
          <Box display="flex" flexDirection={matches ? "column" : "row"} mt={2}>
            <Typography variant="subtypo2" mr={matches ? 0 : 11.4}>
              Discord Link
            </Typography>
            <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
              {collectionData?.discordLink}
            </Typography>
          </Box>
          {collectionData?.tokenArray?.length > 0
            ? collectionData?.tokenArray?.map(({ tokenAddress }) => (
                <Box
                  key={tokenAddress}
                  display="flex"
                  flexDirection={matches ? "column" : "row"}
                  mt={2}
                >
                  <Typography variant="subtypo2" mr={matches ? 0 : 7.8}>
                    Token gated link
                  </Typography>
                  <Typography variant="subtypo" mt={matches ? "10px" : "0px"}>
                    {tokenAddress}
                  </Typography>
                </Box>
              ))
            : null}

          <Box my={4} sx={{ width: "100%", border: "2px solid #f7f7f7" }} />
          <Box
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h1" mb={matches ? 2 : 0}>
              PRODUCTS ({collectionData?.products?.length})
            </Typography>
            <Link to="/createproduct" style={{ textDecoration: "none" }}>
              <Button variant="gradient" sx={{ width: "236px" }}>
                CREATE PRODUCT
              </Button>
            </Link>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection={matches ? "column" : "row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box mb={matches ? 2 : 0}>
              <StyledTabs
                btnName={btnName}
                setTabsValue={setTabsValue}
                tabsValue={tabsValue}
              />
            </Box>
          </Box>
          {tabsValue === 0 ? (
            <ProductList
              products={data?.publishCollectionProduct ?? []}
              collectionId={params?.id}
              message={"All products are un-published!"}
            />
          ) : (
            <ProductList
              products={data?.unPublishCollectionProduct ?? []}
              collectionId={params?.id}
              message={"All products are published!"}
            />
          )}
        </Container>
      </Box>
    </>
  );
}
