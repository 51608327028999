import React, { useState } from "react";
import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { IOSSwitch } from "../Components/SmallComponents/AppComponents";
import {
  useFeaturedWeb2ProductMutation,
  useDeleteSingleWeb2ProductMutation,
} from "../store/web2ProductApi";
import Loading from "../Components/SmallComponents/loading";
import { useDispatch } from "react-redux";
import { openDeleteModal, openErrorModal } from "../store/componentState";

export default function Web2ProductList({ products, message }) {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:960px)");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [featuredWeb2ProductApi] = useFeaturedWeb2ProductMutation();
  const [deleteProductApi] = useDeleteSingleWeb2ProductMutation();

  const handleSwitch = async (e, id) => {
    setLoading(true);
    try {
      const { error } = await featuredWeb2ProductApi({
        id: id,
        status: e.target.checked,
      });
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const deleteProduct = async (id) => {
    try {
      setLoading(true);
      const { error } = await deleteProductApi(id);
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      dispatch(
        openDeleteModal({
          open: false,
          deleteFunction: "",
          id: "",
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <Box
        mt={4}
        sx={{
          width: "100%",
          height: "300px",
          overflowX: "scroll",

          "&::-webkit-scrollbar": {
            width: "0 !important",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "2px !important",
          },
          "&::-webkit-scrollbar:verticle": {
            width: "2px !important",
          },
          "&::-webkit-scrollbar-track": {
            background: "#ffffff",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#2CEA6A",
          },
        }}
      >
        {products?.length > 0 ? (
          products.map(
            (
              {
                _id,
                name,
                images,
                price,
                totalQuantity,
                productType,
                heatPress,
                featured,
              },
              i
            ) => {
              return (
                <Box
                  mb={2}
                  key={i}
                  sx={{
                    height: "80px",
                    width: matches ? "1140px" : "100%",
                    borderRadius: "14px",
                    border: "1px transparent",
                    backgroundImage:
                      " linear-gradient(#F7F7F7, #F7F7F7  ),linear-gradient(37.34deg, #f7f7f7 4.36%, #f7f7f7  48.4%, #f7f7f7  96.11%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box, border-box",
                    padding: "2px",
                    "&:hover": {
                      backgroundImage:
                        " linear-gradient(#F7F7F7, #F7F7F7  ),linear-gradient(269.3deg, #2CEA6A 4.73%, #08A7CC 88.73%)",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                  >
                    <Box display="flex" alignItems="center" width="65%">
                      <img
                        src={`${images[0]}`}
                        width="86px"
                        height="60px"
                        style={{ borderRadius: "8px", objectFit: "cover" }}
                        alt=""
                      />

                      <Typography
                        variant="h3"
                        ml={1}
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {name}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                      width="15%"
                    >
                      <Typography variant="h3">
                        {featured ? "Featured" : "Not Featured"}
                      </Typography>
                      <IOSSwitch
                        checked={featured ?? false}
                        onChange={(e) => handleSwitch(e, _id)}
                      />
                    </Box>
                    <Box display="flex" width="15%" justifyContent="flex-end">
                      <Link
                        to={`/editweb2product/${_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          mr="3px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #DCDCDC",
                            borderRadius: "5px",
                            width: "44px",
                            height: "44px",
                          }}
                        >
                          <Tooltip title="Edit">
                            <EditIcon
                              sx={{ color: "rgba(18, 21, 32, 0.69)" }}
                            />
                          </Tooltip>
                        </Box>
                      </Link>
                      <Box
                        onClick={() =>
                          dispatch(
                            openDeleteModal({
                              open: true,
                              deleteFunction: deleteProduct,
                              id: _id,
                            })
                          )
                        }
                        mr="3px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "#FFFFFF",
                          border: "1px solid #DCDCDC",
                          borderRadius: "5px",
                          width: "44px",
                          height: "44px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Delete">
                          <Delete
                            sx={{
                              color: "#EF5656",
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => navigate(`/web2saveproduct/${_id}`)}
                        sx={{
                          background: "#FFFFFF",
                          border: "1px solid #DCDCDC",
                          borderRadius: "5px",
                          width: "44px",
                          height: "44px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="View Detail">
                          <ArrowForwardIcon
                            sx={{ color: "rgba(18, 21, 32, 0.69)" }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            }
          )
        ) : (
          <Box
            sx={{
              background: "#F6F6F6",
              borderRadius: "14px",
              height: "289px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h1" textAlign="center">
              {message}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
