import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import {
  inputStyle,
} from "../Components/SmallComponents/AppComponents";
import { useAdminInfoMutation } from "../store/adminAuthApi";
import { addToken } from "../services/localStorage";
import { addTokenStore } from "../store/componentState";
import { logo } from "../Components/SmallComponents/Images";

export default function SignIn() {
  const matches = useMediaQuery("(max-width:960px)");
  const [checkError, setCheckError] = useState(null);
  const [signIn] = useAdminInfoMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });



  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (user.password.length === 0 || user.email.length === 0) {
        return setCheckError("Please fill out all required fields.");
      }
      const { data, error } = await signIn(user);

      if (error) {
        return setCheckError("Password and email is not correct.");
      }

      addToken(data?.user?.token);
      setCheckError("");
      dispatch(addTokenStore(data?.user?.token));
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box pt={2} pb={2}>
        <Box
          sx={{
            mx: "auto",
            mt: 8,
            width: { xs: "90%", sm: "70%", md: "608px" },
          }}
        >
          <Box display="flex" justifyContent="center">
            <img src={logo} width="150px" alt="logo" />
          </Box>
          <Box
            mt={5}
            p={matches ? 4 : 6}
            sx={{
              background: "#F6F6F6",
              borderRadius: "18px",
            }}
          >
            <Typography variant="h1" textAlign="center">
              SIGN IN
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <Box mt={matches ? 4 : 6}>
                <input
                  style={{
                    ...inputStyle,

                    border: checkError
                      ? "3px solid #EF5656"
                      : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  type="email"
                  placeholder={"Enter your email"}
                />
              </Box>

              <Box mt={5}>
                <input
                  value={user.password}
                  style={{
                    ...inputStyle,
                    color: "#fff !important",
                    border: checkError
                      ? "3px solid #EF5656"
                      : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                  type="password"
                  placeholder={"Enter the password"}
                />
                {checkError && (
                  <Typography mt={2} color="#EF5656">
                    {checkError}
                  </Typography>
                )}
              </Box>
              <Box mt={6}>
                <Button variant="gradient" type="submit" sx={{ width: "100%" }}>
                  LOGIN
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
