import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { uploadimage } from "../Components/SmallComponents/Images";
import {
  inputBox,
  inputField,
  IOSSwitch,
  inputStyle,
  type,
} from "../Components/SmallComponents/AppComponents";
import { actualImgSize } from "../services/hadleNumberInput";
import {
  useUpdateCollectionMutation,
  useGetSingleCollectionQuery,
} from "../store/collectionApi";
import Loading from "../Components/SmallComponents/loading";
import { BackButton } from "../Components/SmallComponents/BackButton";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../store/componentState";
import { DeleteForever } from "@mui/icons-material";
import { ToastNotify } from "../Components/SmallComponents/AppComponents";
const CreateCollection = () => {
  const dispatch = useDispatch();
  const [baseURL, setBaseURL] = useState("");
  const [logoImg, setLogoImg] = useState("");
  const [featured, setFeatured] = useState(false);
  const [tokenGated, setTokenGated] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { data, isError, isLoading } = useGetSingleCollectionQuery(params?.id);
  const [collectionData, setCollectionData] = useState({});
  const [updateCollection] = useUpdateCollectionMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "tokenArray",
  });
  const watchFieldArray = watch("tokenArray");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const handleTokenGated = () => {
    setTokenGated(!tokenGated);
    if (controlledFields.length <= 0) {
      append();
    }
  };
  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };
  const onSubmits = async (collectionData) => {
    try {
      collectionData.logo = logoImg;
      collectionData.featured = featured;
      // eslint-disable-next-line array-callback-return
      const newData = collectionData?.tokenArray?.filter((info) => {
        if (info?.tokenAddress.length > 0) {
          return info;
        }
      });
      collectionData.tokenArray = newData;

      const formData = new FormData();
      if (logoImg) {
        formData.append("file", collectionData.logo);
      }
      formData.append("data", JSON.stringify(collectionData));
      setLoading(true);
      const { error } = await updateCollection({
        id: params?.id,
        updateCollection: formData,
      });

      if (error) {
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      navigate(-1);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFile = async (event) => {
    if (event.target.files[0]?.size > actualImgSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 10 mb."
      );
    } else {
      let file = event.target.files[0];
      setLogoImg(file);
      const urlFILE = URL.createObjectURL(file);
      setBaseURL(urlFILE);
    }
  };

  useEffect(() => {
    if (isError) {
      return console.log(isError);
    }
    setCollectionData(data?.data);
    setTokenGated(data?.data?.tokenArray?.length > 0 ? true : false);
    //all data expect logo
    setFeatured(data?.data?.featured ?? false);
    reset({
      name: data?.data?.name,
      description: data?.data?.description,
      featured: data?.data?.featured,
      twitterLink: data?.data?.twitterLink,
      discordLink: data?.data?.discordLink,
      tokenAddress: data?.data?.tokenAddress ?? "",
      tokenArray:
        data?.data?.tokenArray.length > 0
          ? data?.data?.tokenArray
          : [
              {
                tokenAddress: "",
              },
            ],
    });
  }, [data?.data, isError, reset]);

  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "65px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            md: "row",
          }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            sx={{
              ml: { xs: 0, md: 31 },
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              textAlign={{ xs: "center", md: "left" }}
              textTransform={"uppercase"}
              mt={{ xs: 2, md: 0 }}
            >
              Edit Collection
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmits)}>
          <Box
            sx={{
              margin: "auto",
              width: {
                xs: "90%",
                md: "690px",
              },
            }}
          >
            <Box ml={{ xs: 0, md: 12.5 }} mt={"59px"}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#121520",
                  fontWeight: 400,
                  mb: "14px",
                }}
              >
                Name *
              </Typography>
              <Box>
                <input
                  type="text"
                  {...register("name", {
                    required: true,
                  })}
                  // defaultValue={collectionData?.name}
                  placeholder="Collection Name"
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.name && errors.name.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
              </Box>
              {errors.name && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#121520",
                  fontWeight: 400,
                  mb: "14px",
                  mt: "45px",
                }}
              >
                LOGO *
              </Typography>
              <Box
                sx={{
                  background: "#F5F5F5",
                  border: "3px dashed rgba(82, 89, 96, 0.26)",
                  borderRadius: "18px",
                  width: "200px",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <label
                  htmlFor="logoImg"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <input
                    id="logoImg"
                    type="file"
                    accept="image/*"
                    {...register("logo", {
                      required: false,
                    })}
                    onChange={handleFile}
                    style={{ display: "none" }}
                  />
                  {baseURL || collectionData?.logo ? (
                    <img
                      src={baseURL ? baseURL : `${collectionData?.logo}`}
                      alt=""
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                    />
                  ) : (
                    <>
                      {" "}
                      <img src={uploadimage} type="file" alt="imgaeuploader" />
                      <Typography
                        sx={{
                          color: "#121520",
                          fontWeight: 400,
                          my: "14px",
                        }}
                      >
                        Upload image
                      </Typography>{" "}
                    </>
                  )}
                </label>
              </Box>
              {errors.logo && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}
              <Typography sx={type}>Description *</Typography>
              <Box
                sx={{
                  height: "204px",
                }}
              >
                <textarea
                  type="text"
                  // defaultValue={collectionData?.description}
                  {...register("description", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.description &&
                      errors.description.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                    height: "204px",
                  }}
                />
                {errors.description && (
                  <Typography
                    mt={0.5}
                    fontSize={"12px !important"}
                    color="#EF5656"
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Typography sx={type}>Twitter link *</Typography>
              <Box>
                <input
                  type="url"
                  // defaultValue={collectionData?.twitterLink}
                  placeholder="https://twitter.com/@handleName"
                  {...register("twitterLink", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.twitterLink &&
                      errors.twitterLink.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
                {errors.twitterLink && (
                  <Typography
                    mt={0.5}
                    fontSize={"12px !important"}
                    color="#EF5656"
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Typography sx={type}>Dsicord link *</Typography>
              <Box>
                <input
                  type="url"
                  // defaultValue={collectionData?.discordLink}
                  placeholder="https://discord.com/@handleName"
                  {...register("discordLink", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.discordLink &&
                      errors.discordLink.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
                {errors.discordLink && (
                  <Typography
                    mt={0.5}
                    fontSize={"12px !important"}
                    color="#EF5656"
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Typography sx={type}>Token Gated Links</Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                  pb: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px !important",
                    lineHeight: "21px",
                    letterSpacing: "0.03em",
                    color: "rgba(18, 21, 32, 0.69)",
                  }}
                >
                  Add one or more collection contract addresses to token gate.
                </Typography>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={tokenGated}
                      sx={{ m: 1 }}
                      value={tokenGated}
                      onClick={handleTokenGated}
                    />
                  }
                />
              </Box>
              {tokenGated === true && (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Box
                        key={field.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                          pb: "20px",
                          gap: "10px",
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            ...inputBox,
                            height: "54px",
                            width: "100%",
                            px: 2,
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter Collection Address Here"
                            key={field.id} // important to include key with field's id
                            {...register(`tokenArray.${index}.tokenAddress`)}
                            // value={inputState[index].value}
                            // {...register("tokenAddress", {
                            //   required: false,
                            // })}
                            // onChange={(e) => handleToken(e, index)}
                            spellCheck="false"
                            style={{
                              ...inputField,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            border: "3px solid rgba(82, 89, 96, 0.26)",
                            borderRadius: "18px",
                            display: "flex",
                            height: "54px",
                            width: "54px",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            disableRipple
                            onClick={() => {
                              remove(index);
                              if (fields.length === 1 && tokenGated === true) {
                                setTokenGated(false);
                              }
                            }}
                          >
                            <DeleteForever
                              sx={{
                                fontSize: "25px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}

                  <Box mt={2}>
                    <Button variant="gradient" onClick={() => append()}>
                      ADD Token
                    </Button>
                  </Box>
                </>
              )}
              <Box display="flex" justifyContent="flex-end" mt={"30px"}>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    border: "2px solid rgba(82, 89, 96, 0.26)",
                    borderRadius: "10px",
                    textTransform: "uppercase",
                    color: "#121520",
                    mr: "8px",
                    py: "10px",
                    px: "30px",
                    fontSize: "16px !important",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="gradient"
                  type="submit"
                  sx={{
                    px: "30px",
                    py: "10px",
                  }}
                >
                  Update Collection
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default CreateCollection;
