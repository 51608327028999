import { Alert, Box, Snackbar, styled, Switch } from "@mui/material";
import { Button } from "@mui/material";

export const inputStyle = {
  backgroundColor: "transparent",
  height: "60px",
  background: "#FFFFFF",
  border: "3px solid rgba(82, 89, 96, 0.26)",
  borderRadius: "18px",
  outline: "none",
  paddingLeft: "16px",
  paddingRight: "16px",
  fontSize: "17px",
  fontFamily: "Open Sans",
  fontWight: 400,
  width: "100%",
  color: "#000",
  flexBasis: "100%",
  paddingTop: "10px",
  paddingBottom: "10px",
};
export const gradientButton = {
  border: "1px transparent",
  backgroundImage:
    " linear-gradient(#121B25, #121B25  ),linear-gradient(180deg, #2CEA6A 0%, #08A7CC 100%)",
  backgroundOrigin: "border-box",
  backgroundClip: "content-box, border-box",
  padding: "2px",
  borderRadius: "14px",
};
export const inputBox = {
  display: "flex",
  alignItems: "center",
  height: "60px",
  background: "#FFFFFF",
  border: "3px solid rgba(82, 89, 96, 0.26)",
  borderRadius: "18px",
  px: 2,
};
export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 58,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: "#fff",

      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 25,
  },
  "& .MuiSwitch-track": {
    borderRadius: "15px",
    backgroundColor:
      theme.palette.mode === "light"
        ? "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%"
        : "",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export const inputField = {
  backgroundColor: "transparent",
  outline: "none",
  border: "none",
  fontSize: "18px !important",
  fontFamily: "Open Sans",
  fontWight: 400,
  width: "100%",
  color: "#000",
  flexBasis: "100%",
  paddingTop: "10px",
  paddingBottom: "10px",
};

export const type = {
  textTransform: "uppercase",
  color: "#121520",
  fontWeight: 400,
  mb: "14px",
  mt: "45px",
  fontSize: "16px !important",
};

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "#15BDAC",
          background:
            "linear-gradient(231.13deg, rgba(8, 165, 207, 0.18) 14.71%, rgba(47, 239, 100, 0.18) 87.56%)",
          fontSize: "14px",
          fontFamily: "Open Sans",
          borderRadius: "10px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background:
              "linear-gradient(231.13deg, rgba(8, 165, 207, 0.18) 14.71%, rgba(47, 239, 100, 0.18) 87.56%)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledInput({ fieldValue, changeField, type, placeholder }) {
  return (
    <>
      <input
        type={type}
        value={fieldValue}
        onChange={changeField}
        placeholder={placeholder}
        spellCheck="false"
        style={{
          backgroundColor: "transparent",
          height: "60px",
          background: "#FFFFFF",
          border: "3px solid rgba(82, 89, 96, 0.26)",
          borderRadius: "18px",
          outline: "none",
          paddingLeft: "16px",
          paddingRight: "16px",
          fontSize: "17px",
          fontFamily: "Open Sans",
          fontWight: 400,
          width: "100%",
          color: "#000",
          flexBasis: "100%",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      />
    </>
  );
}
export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "18px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "500",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}

export function StyledDownloadButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "white",
          background:
            "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
          opacity: "1",
          borderRadius: "5px",
          padding: "12px 20px",
          border: "none",
          fontWeight: "bold",
          fontFamily: "Open Sans",
          "&:hover": {
            background:
              "linear-gradient(231.13deg, #08A5CF 14.71%, #2FEF64 87.56%)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}
