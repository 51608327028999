import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StyledDownloadButton } from "../SmallComponents/AppComponents";
import { NewOrder } from "./newOrder";
import StyledTabs from "../../Pages/StyledTabs";
import {
  useGetAllOrdersQuery,
  useGetJsonOrderMutation,
} from "../../store/OrderApi";
import Loading from "../SmallComponents/loading";

import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../../store/componentState";

const btnName = ["New", "Processed"];

export default function OrderTabs() {
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:960px)");
  const [tabsValue, setTabsValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { data = [], isLoading } = useGetAllOrdersQuery();
  const [getJsonOrderDetail] = useGetJsonOrderMutation();

  /// download excel file function
  const downloadXlFile = async (status, fileName) => {
    try {
      setLoading(true);
      const { data, error } = await getJsonOrderDetail(status);
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }

      const worksheet = XLSX.utils.json_to_sheet(data?.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet");
      XLSX.write(workbook, {
        bookType: "xlsx",
        type: "buffer",
      });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Loading loading={isLoading} />
      <Loading loading={loading} />

      <Box sx={{ pt: { xs: 5, md: 10 } }}>
        <Box
          display="flex"
          flexDirection={matches ? "column" : "row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h1" mb={matches ? 2 : 0} mt={matches ? 0 : 0.5}>
            Order ({data?.totalOrders ?? 0})
          </Typography>

          <Box>
            <StyledTabs
              btnName={btnName}
              setTabsValue={setTabsValue}
              tabsValue={tabsValue}
            />
          </Box>
        </Box>
        {data?.newOrders?.length > 0 && tabsValue === 0 ? (
          <Box
            display="flex"
            justifyContent={matches ? "center" : "end"}
            mt={2}
          >
            <StyledDownloadButton
              onClick={() => downloadXlFile(false, "newordersdetial")}
            >
              Download New Order Details
            </StyledDownloadButton>
          </Box>
        ) : null}
        {data?.processedOrders?.length > 0 && tabsValue === 1 ? (
          <Box
            display="flex"
            justifyContent={matches ? "center" : "end"}
            mt={2}
          >
            <StyledDownloadButton
              onClick={() => downloadXlFile(true, "processedordersdetial")}
            >
              Download Processed Order Details
            </StyledDownloadButton>
          </Box>
        ) : null}
        <Box
          sx={{
            mt: 5,
            overflow: "scroll",
            height: "300px",

            "&::-webkit-scrollbar": {
              width: 1,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "none",
            },
            "&::-webkit-scrollbar:horizontal": {
              height: "0px !important",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#fff",
              borderRadius: 2,
            },
          }}
        >
          {tabsValue === 0 ? (
            <NewOrder data={data?.newOrders} />
          ) : (
            <NewOrder data={data?.processedOrders} />
          )}
        </Box>
      </Box>
    </>
  );
}
