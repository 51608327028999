import { api } from "./api";
export const web3ProductApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    addWeb3Product: builder.mutation({
      query: (product) => {
        return {
          url: "/add-web3-product",
          method: "POST",
          body: product,
        };
      },
      invalidatesTags: ["getSingleCollection", "getAllCollection"],
    }),
    getSingleWeb3Product: builder.query({
      query: (productId) => {
        return {
          url: `/find-web3-product/${productId}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleWeb3Product"],
    }),
    deleteSingleWeb3Product: builder.mutation({
      query: (product) => {
        const { productId, collectionId } = product;
        return {
          url: `/delete-web3-product/${productId}`,
          method: "DELETE",
          body: { collectionId },
        };
      },
      invalidatesTags: ["getSingleCollection", "getAllCollection"],
    }),
    publishWeb3Product: builder.mutation({
      query: (publishPayload) => {
        const { id, status } = publishPayload;
        return {
          url: `/publish-unpublish-web3-product/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["getSingleCollection"],
    }),
    featuredWeb3Product: builder.mutation({
      query: (updateStatusPayload) => {
        const { id, status, collectionId } = updateStatusPayload;
        return {
          url: `/check-feature-product-collection/${id}`,
          method: "PUT",
          body: { status, collectionId },
        };
      },
      invalidatesTags: [
        "getSingleCollection",
        "getAllCollection",
        "getAllFeaturedCollection",
      ],
    }),
    updateWeb3Product: builder.mutation({
      query: (updatePayload) => {
        const { id, updateProduct } = updatePayload;
        return {
          url: `/update-web3-product/${id}`,
          method: "PUT",
          body: updateProduct,
        };
      },
      invalidatesTags: [
        "getSingleCollection",
        "getAllCollection",
        "getSingleWeb3Product",
      ],
    }),
  }),
});

export const {
  useAddWeb3ProductMutation,
  useGetSingleWeb3ProductQuery,
  useDeleteSingleWeb3ProductMutation,
  usePublishWeb3ProductMutation,
  useFeaturedWeb3ProductMutation,
  useUpdateWeb3ProductMutation,
} = web3ProductApi;
