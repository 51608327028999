import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DeleteForever } from "@mui/icons-material";
import { uploadimage } from "./SmallComponents/Images";
import { actualImgSize } from "../services/hadleNumberInput";
import {
  inputBox,
  inputField,
  inputStyle,
  IOSSwitch,
  ToastNotify,
  type,
} from "./SmallComponents/AppComponents";
import { useCreateCollectionMutation } from "../store/collectionApi";
import Loading from "./SmallComponents/loading";
import { BackButton } from "./SmallComponents/BackButton";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../store/componentState";

const CreateCollection = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [baseURL, setBaseURL] = useState("");
  const [logoImg, setLogoImg] = useState("");

  const [tokenGated, setTokenGated] = useState(false);

  const [createCollection] = useCreateCollectionMutation();

  const navigate = useNavigate();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tokenArray: [
        {
          tokenAddress: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "tokenArray", // unique name for your Field Array
  });

  const watchFieldArray = watch("tokenArray");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };

  const onSubmits = async (collectionData) => {
    try {
      if (logoImg.length <= 0) {
        return toastShowing("Please upload logo");
      }
      collectionData.logo = logoImg;
      collectionData.featured = false;
      // eslint-disable-next-line array-callback-return
      const newData = collectionData?.tokenArray?.filter((info) => {
        if (info?.tokenAddress.length > 0) {
          return info;
        }
      });
      collectionData.tokenArray = newData;
      const formData = new FormData();
      formData.append("file", collectionData.logo);
      formData.append("data", JSON.stringify(collectionData));
      setLoading(true);
      const { data, error } = await createCollection(formData);

      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      navigate(`/savecollection/${data?.data?._id}`);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleTokenGated = () => {
    setTokenGated(!tokenGated);
    if (controlledFields.length <= 0) {
      append();
    }
  };

  const handleFile = async (event) => {
    if (event.target.files[0]?.size > actualImgSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 10 mb."
      );
    } else {
      let file = event.target.files[0];
      setLogoImg(file);
      const urlFILE = URL.createObjectURL(file);
      setBaseURL(urlFILE);
    }
  };
  // const handleFeatureSwitch = (e) => {
  //   if (data?.status ?? false) {
  //     return setFeatured(e.target.checked);
  //   }
  //   toastShowing(data?.message);
  //   setFeatured(false);
  // };

  // const deleteInput = (index) => {
  //   // eslint-disable-next-line array-callback-return
  //   const newData = inputState.filter((info, i) => {
  //     if (index !== i) {
  //       return info;
  //     }
  //   });
  //   setInputState(newData);
  // };
  // const handleToken = (e, index) => {
  //   console.log(index);
  //   const token = e.target.value;
  //   const newData = [...inputState];
  //   newData[index] = { ...newData[index], token };
  //   // newData[index].value = e.target.value;
  //   setInputState(newData);
  // };

  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "65px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            md: "row",
          }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            sx={{
              ml: { xs: 0, md: 31 },
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              textAlign={{ xs: "center", md: "left" }}
              textTransform={"uppercase"}
              mt={{ xs: 2, md: 0 }}
            >
              Create Collection
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmits)}>
          <Box
            sx={{
              margin: "auto",
              width: {
                xs: "90%",
                md: "690px",
              },
            }}
          >
            <Box ml={{ xs: 0, md: 12.5 }} mt={"59px"}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#121520",
                  fontWeight: 400,
                  mb: "14px",
                }}
              >
                Name *
              </Typography>

              <input
                type="text"
                placeholder="Collection Name"
                {...register("name", {
                  required: true,
                })}
                spellCheck="false"
                style={{
                  ...inputStyle,
                  border:
                    errors.name && errors.name.type === "required"
                      ? "3px solid #EF5656"
                      : "3px solid rgba(82, 89, 96, 0.26)",
                }}
              />

              {errors.name && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: "#121520",
                  fontWeight: 400,
                  mb: "14px",
                  mt: "45px",
                }}
              >
                LOGO *
              </Typography>
              <Box
                sx={{
                  background: "#F5F5F5",
                  border: "3px dashed rgba(82, 89, 96, 0.26)",
                  borderRadius: "18px",
                  width: "200px",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <label
                  htmlFor="logoImg"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="logoImg"
                    type="file"
                    accept="image/*"
                    {...register("logo", {
                      required: false,
                    })}
                    onChange={handleFile}
                    style={{ display: "none" }}
                  />
                  {baseURL ? (
                    <img
                      src={baseURL}
                      alt=""
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                    />
                  ) : (
                    <>
                      {" "}
                      <img src={uploadimage} type="file" alt="imgaeuploader" />
                      <Typography
                        sx={{
                          color: "#121520",
                          fontWeight: 400,
                          my: "14px",
                        }}
                      >
                        Upload image
                      </Typography>{" "}
                    </>
                  )}
                </label>
              </Box>
              {errors.logo && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}
              <Typography sx={type}>Description *</Typography>
              <Box>
                <textarea
                  type="text"
                  {...register("description", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.description &&
                      errors.description.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                    height: "204px",
                  }}
                />
                {errors.description && (
                  <Typography
                    mt={0.5}
                    fontSize={"12px !important"}
                    color="#EF5656"
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Typography sx={type}>Twitter link *</Typography>
              <Box>
                <input
                  type="url"
                  placeholder="https://twitter.com/@handleName"
                  {...register("twitterLink", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.twitterLink &&
                      errors.twitterLink.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
                {errors.twitterLink && (
                  <Typography
                    mt={0.5}
                    fontSize={"12px !important"}
                    color="#EF5656"
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Typography sx={type}>Dsicord link *</Typography>
              <Box>
                <input
                  type="url"
                  placeholder="https://discord.com/@handleName"
                  {...register("discordLink", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.discordLink &&
                      errors.discordLink.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
                {errors.discordLink && (
                  <Typography
                    mt={0.5}
                    fontSize={"12px !important"}
                    color="#EF5656"
                  >
                    This field is required
                  </Typography>
                )}
              </Box>
              <Typography sx={type}>Token Gated Links</Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                  pb: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px !important",
                    lineHeight: "21px",
                    letterSpacing: "0.03em",
                    color: "rgba(18, 21, 32, 0.69)",
                  }}
                >
                  Add one or more collection contract addresses to token gate.
                </Typography>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={tokenGated}
                      value={tokenGated}
                      onClick={handleTokenGated}
                    />
                  }
                />
              </Box>

              {tokenGated === true ? (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Box
                        key={field.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                          pb: "20px",
                          gap: "10px",
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            ...inputBox,
                            height: "54px",
                            width: "100%",
                            px: 2,
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter Collection Address Here"
                            key={field.id} // important to include key with field's id
                            {...register(`tokenArray.${index}.tokenAddress`)}
                            // v alue={inputState[index].value}
                            // {...register("tokenAddress", {
                            //   required: false,
                            // })}
                            // onChange={(e) => handleToken(e, index)}
                            spellCheck="false"
                            style={{
                              ...inputField,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            border: "3px solid rgba(82, 89, 96, 0.26)",
                            borderRadius: "18px",
                            display: "flex",
                            height: "54px",
                            width: "54px",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            disableRipple
                            onClick={() => {
                              remove(index);
                              if (fields.length === 1 && tokenGated === true) {
                                setTokenGated(false);
                              }
                            }}
                          >
                            <DeleteForever
                              sx={{
                                fontSize: "25px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}

                  <Box mt={2}>
                    <Button variant="gradient" onClick={() => append()}>
                      ADD Token
                    </Button>
                  </Box>
                </>
              ) : null}

              {/* <Typography
                sx={{
                  pt: "44px",
                  fontSize: "16px !important",
                  color: "#121520",
                }}
              >
                Featured Collection
              </Typography> */}
              {/* <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                  pb: "40px",
                  mt: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px !important",
                    lineHeight: "21px",
                    letterSpacing: "0.03em",
                    color: "rgba(18, 21, 32, 0.69)",
                  }}
                >
                  Lörem ipsum semimorär åmarad viktiga för att vadöns <br />
                  tasigförsamhet.{" "}
                </Typography>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={featured}
                      sx={{ m: 1 }}
                      onChange={handleFeatureSwitch}
                    />
                  }
                />
              </Box> */}
              <Box display="flex" justifyContent="flex-end" mt={"30px"}>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    border: "2px solid rgba(82, 89, 96, 0.26)",
                    borderRadius: "10px",
                    textTransform: "uppercase",
                    color: "#121520",
                    mr: "8px",
                    py: "10px",
                    px: "30px",
                    fontSize: "16px !important",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="gradient"
                  type="submit"
                  sx={{
                    px: "30px",
                    py: "10px",
                  }}
                >
                  Save Collection
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default CreateCollection;
