import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import { Typography2 } from "./Typography2";
import { Link } from "react-router-dom";

export const NewOrder = ({ data }) => {
  return (
    <TableContainer
      sx={{
        "&::-webkit-scrollbar": {
          width: 1,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "none",
        },
        "&::-webkit-scrollbar:horizontal": {
          height: "0px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#F6F6F6",
          borderRadius: 2,
        },
      }}
    >
      <Table
        aria-label="custom pagination table"
        // borderSpacing="0 15px"
        sx={{ borderCollapse: "separate" }}
      >
        <TableBody
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            rowGap: "20px",
          })}
        >
          {data?.length > 0 ? (
            data?.map(
              (
                {
                  _id,
                  products,
                  shippingMethod,
                  createdAt,
                  payWith,
                  amount,
                  orderNo,
                },
                i
              ) => {
                return (
                  <TableRow
                    key={i}
                    sx={(theme) => ({
                      background: theme.palette.background.offWhite,
                      borderRadius: "8px",
                    })}
                  >
                    <TableCell
                      style={{
                        minWidth: "200px",
                        // maxWidth: "220px",
                        border: "none",
                      }}
                      align="left"
                    >
                      <Box>
                        <Typography2>Order No:</Typography2>
                        <Typography variant="subtypo" fontWeight="600">
                          {orderNo}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "150px",
                        // maxWidth: "200px",
                        border: "none",
                      }}
                      align="left"
                    >
                      <Box>
                        <Typography2>${amount}</Typography2>
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "200px",
                        // maxWidth: "250px",
                        border: "none",
                      }}
                      align="left"
                    >
                      <Box>
                        <Typography2>Card Checkout</Typography2>
                        <Typography2>
                          {products?.length}
                          {products?.length > 1 ? " Products" : " Product"}
                        </Typography2>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "250px",
                        maxWidth: "300px",
                        border: "none",
                      }}
                      align="left"
                    >
                      <Box>
                        {/* <Typography2>
                          Exp. Delivery{" "}
                          {new Date(createdAt).toLocaleDateString()}
                        </Typography2> */}
                        <Typography2>
                          Received {new Date(createdAt).toLocaleDateString()}
                        </Typography2>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "250px",
                        // maxWidth: "300px",
                        border: "none",
                      }}
                      align="left"
                    >
                      <Box>
                        <Typography2>
                          {shippingMethod === "19.99"
                            ? "USA and Canada"
                            : shippingMethod === "100"
                            ? "World Wide"
                            : shippingMethod === "200"
                            ? "China"
                            : shippingMethod === "1"
                            ? "Bahrain"
                            : "N/A"}
                        </Typography2>
                        <Typography2>{payWith} Payment</Typography2>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "50px",
                        // maxWidth: "100px",
                        color: "white",
                        border: "none",
                      }}
                    >
                      <Link
                        to={`/orderDetail/${_id}`}
                        style={{
                          textDecoration: "none",
                          color: "#595B62",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="View Detail">
                          <IconButton
                            sx={{ background: "#FFFFFF", borderRadius: "4px" }}
                          >
                            <ArrowForwardIcon color="#595B62" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              }
            )
          ) : (
            <TableRow>
              <TableCell style={{ width: "20%" }}>
                <Box
                  sx={{
                    background: "#F6F6F6",
                    borderRadius: "14px",
                    height: "289px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h1" textAlign="center">
                    No orders yet
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
