import { createSlice } from "@reduxjs/toolkit";

export const componentStateSlice = createSlice({
  name: "token",
  initialState: {
    token: "",
    deleteConfirm: {
      open: false,
      deleteFunction: "",
      id: "",
    },
    errorState: {
      open: false,
      errorMessage: "",
    },
    orderProcessed: {
      open: false,
      processedOrderFunction: "",
      id: "",
    },
    collectionState: {
      collectionName: "",
      collectionId: "",
    },
    deleteCollectionProductId: "",
  },
  reducers: {
    addTokenStore: (state, action) => {
      state.token = action.payload;
    },
    openDeleteModal: (state, action) => {
      state.deleteConfirm = action.payload;
    },
    openErrorModal: (state, action) => {
      state.errorState = action.payload;
    },
    openOrderProcessedModal: (state, action) => {
      state.orderProcessed = action.payload;
    },
    collectionStateHandle: (state, action) => {
      state.collectionState = action.payload;
    },
    collectionProductDeleteHandle: (state, action) => {
      state.deleteCollectionProductId = action.payload;
    },
  },
});

export const {
  addTokenStore,
  openDeleteModal,
  openErrorModal,
  openOrderProcessedModal,
  collectionStateHandle,
  collectionProductDeleteHandle,
} = componentStateSlice.actions;

export default componentStateSlice.reducer;
