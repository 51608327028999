import { api } from "./api";

export const orderApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => {
        return {
          url: `/all-orders`,
          method: "GET",
        };
      },
      providesTags: ["getAllOrder"],
      invalidatesTags: [
        "getSingleCollection",
        "getAllCollection",
        "getAllWeb2Product",
        "getAllWeb2FeaturedProduct",
        "getSingleWeb3Product",
      ],
    }),
    getSingleOrders: builder.query({
      query: (orderid) => {
        return {
          url: `/single-orders/${orderid}`,
          method: "GET",
        };
      },
      providesTags: ["getSingleOrder"],
    }),
    processedOrder: builder.mutation({
      query: (processedPayload) => {
        const { id, status } = processedPayload;
        return {
          url: `/update-order-status/${id}`,
          method: "PUT",
          body: { status },
        };
      },
      invalidatesTags: ["getAllOrder", "getSingleOrder"],
    }),
    getJsonOrder: builder.mutation({
      query: (status) => {
        return {
          url: `/all-json-orders`,
          method: "POST",
          body: { status: status },
        };
      },
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useGetSingleOrdersQuery,
  useProcessedOrderMutation,
  useGetJsonOrderMutation,
} = orderApi;
