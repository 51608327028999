import React from "react";
import { Button } from "@mui/material";

const CustomCencelBtn = ({cencel}) => {
  return (
    <>
      <Button
        sx={{
            fontFamily: 'Open Sans',
          width: "100%",
          height: "40px",
          padding: "5px",
          float: "right",
         color:"rgba(18, 27, 37, 0.69)",
          background: " #FFFFFF",
          border:"1px solid #DCDCDC",
          zIndex: 1,
          "&:hover": {
            background:
              "#FFFFFF",
          },
          borderRadius:"14px"
        }}
      >
       {cencel}
      </Button>
    </>
  );
};

export default CustomCencelBtn;