import { configureStore } from "@reduxjs/toolkit";
import { api as mainApi } from "./api";
import tokenReducer from "./componentState";
import { setupListeners } from "@reduxjs/toolkit/query";

const store = configureStore({
  reducer: {
    componentState: tokenReducer,
    [mainApi.reducerPath]: mainApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      mainApi.middleware
    ),
});
setupListeners(store.dispatch);
export default store;
