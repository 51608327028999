import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { handleKeyDown, handleWheel } from "../services/hadleNumberInput";
import { useForm } from "react-hook-form";
import { useAddDiscountMutation } from "../store/dicountApi";
import {
  inputStyle,
  IOSSwitch,
  ToastNotify,
  type,
} from "./SmallComponents/AppComponents";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../store/componentState";
import { BackButton } from "./SmallComponents/BackButton";
import Loading from "./SmallComponents/loading";

export default function CreateDiscount() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeDiscount, setActiveDiscount] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [createDiscountApi] = useAddDiscountMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmitFunction = async (discountData) => {
    try {
      discountData.activeDiscount = activeDiscount;

      const date = new Date(discountData.discountExpDate);

      const unixTimestamp = Math.floor(date.getTime() / 1000);
      discountData.discountExpDate = unixTimestamp;
      setLoading(true);
      const { error } = createDiscountApi(discountData);
      if (error) {
        setLoading(false);
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      navigate(-1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "65px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            md: "row",
          }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            sx={{
              ml: { xs: 0, md: 31 },
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              textAlign={{ xs: "center", md: "left" }}
              textTransform={"uppercase"}
              mt={{ xs: 2, md: 0 }}
            >
              CREATE DISCOUNT
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmitFunction)}>
          <Box
            sx={{
              margin: "auto",
              width: {
                xs: "90%",
                md: "690px",
              },
            }}
          >
            <Box ml={{ xs: 0, md: 12.5 }} mt={"59px"}>
              <Typography sx={type}>DISCOUNT CODE*</Typography>
              <Box>
                <input
                  type="text"
                  placeholder="CODE123"
                  {...register("discountCode", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.discountCode &&
                      errors.discountCode.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
              </Box>
              {errors.discountCode && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{
                  xs: "column",
                  md: "row",
                }}
                columnGap="30px"
              >
                <Box
                  width={{
                    xs: "100%",
                    md: "48%",
                  }}
                >
                  <Typography
                    sx={{
                      ...type,
                      fontSize: "16px !important",
                      textTransform: "uppercase",
                    }}
                  >
                    Discount percentage *
                  </Typography>
                  <Box>
                    <input
                      type="number"
                      placeholder="0%"
                      {...register("discount", {
                        required: true,
                      })}
                      min="0"
                      onKeyDown={handleKeyDown}
                      onWheel={handleWheel}
                      spellCheck="false"
                      style={{
                        ...inputStyle,
                        border:
                          errors.discount && errors.discount.type === "required"
                            ? "3px solid #EF5656"
                            : "3px solid rgba(82, 89, 96, 0.26)",
                      }}
                    />
                  </Box>
                  {errors.discount && (
                    <Typography
                      mt={0.5}
                      fontSize={"12px !important"}
                      color="#EF5656"
                    >
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box
                  width={{
                    xs: "100%",
                    md: "48%",
                  }}
                >
                  <Typography
                    sx={{
                      ...type,
                      fontSize: "16px !important",
                      textTransform: "uppercase",
                    }}
                  >
                    Expiry date *
                  </Typography>
                  <Box>
                    <input
                      type="date"
                      placeholder="01/01/2024"
                      {...register("discountExpDate", {
                        required: true,
                      })}
                      spellCheck="false"
                      style={{
                        ...inputStyle,
                        border:
                          errors.discountExpDate &&
                          errors.discountExpDate.type === "required"
                            ? "3px solid #EF5656"
                            : "3px solid rgba(82, 89, 96, 0.26)",
                      }}
                    />
                  </Box>
                  {errors.discountExpDate && (
                    <Typography
                      mt={0.5}
                      fontSize={"12px !important"}
                      color="#EF5656"
                    >
                      This field is required
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                mt={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                  pb: "20px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    color: "#121520",
                    fontWeight: 400,
                    fontSize: "16px !important",
                  }}
                >
                  ACTIVE DISCOUNT
                </Typography>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={activeDiscount}
                      onClick={() => setActiveDiscount(!activeDiscount)}
                    />
                  }
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={"30px"}>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    border: "2px solid rgba(82, 89, 96, 0.26)",
                    borderRadius: "10px",
                    textTransform: "uppercase",
                    color: "#121520",
                    mr: "8px",
                    py: "10px",
                    px: "30px",
                    fontSize: "16px !important",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="gradient"
                  sx={{
                    px: "30px",
                    py: "10px",
                  }}
                >
                  Save DISCOUNT
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </>
  );
}
