import { Navigate, Outlet } from "react-router-dom";
import Header from "./Components/Header";
import { Box } from "@mui/material";

export const useAuth = () => {
  if (localStorage.getItem("token")) {
    const user = { loggedIn: true };
    return user && user.loggedIn;
  } else {
    const user = { loggedIn: false };
    return user && user.loggedIn;
  }
};
const PrivateRoute = () => {
  const isAuth = useAuth();
  return isAuth ? (
    <>
      <Header />
      <Outlet />
      <Box py={2} mt={5}></Box>
    </>
  ) : (
    <Navigate to="/signin" />
  );
};

export default PrivateRoute;
