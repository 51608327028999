import React from "react";
// import { AppContext } from "../utils";
import { Close, Apps, ShoppingCart, Inventory } from "@mui/icons-material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { logo } from "./SmallComponents/Images";
import { useNavigate } from "react-router-dom";

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  Box,
  Container,
} from "@mui/material";
import { NavLink, Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { addTokenStore } from "../store/componentState";

const sideArray = [
  {
    name: "Collections",
    to: "/",
    icon: (
      <Apps
        sx={{
          fontSize: "25px",
        }}
      />
    ),
  },
  {
    name: "Products",
    to: "/web2product",
    icon: (
      <Inventory
        sx={{
          fontSize: "25px",
        }}
      />
    ),
  },
  {
    name: "Orders",
    to: "/order",
    icon: (
      <ShoppingCart
        sx={{
          fontSize: "25px",
        }}
      />
    ),
  },
  {
    name: "Discount",
    to: "/discount",
    icon: (
      <LocalOfferIcon
        sx={{
          fontSize: "25px",
        }}
      />
    ),
  },
];

const textGraident = {
  borderBottom: "3px solid transparent",
  borderImage: "linear-gradient(267.4deg, #08A5CF 15.07%, #2FEF64 80.58%)",
  borderImageSlice: "1",
  width: "max-content",
};
const navStyle = {
  textDecoration: "none",
  fontFamily: "Roboto sans-serif",
  fontWeight: 400,
  fontSize: "16px",
  transition: "0.4s",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const Header = ({ token }) => {
  const { pathname } = useLocation();
  const matches = useMediaQuery("(max-width:900px)");
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpenNavMenu = React.useCallback((event) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleCloseNavMenu = React.useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const logOut = () => {
    localStorage.removeItem("token");
    navigate("/signin");
    dispatch(addTokenStore(""));
  };
  return (
    <>
      <Box
        height={{ xs: "65px", md: "89px" }}
        backgroundColor="#FFFFFF"
        display="flex"
        py={3}
        borderBottom="3px solid rgba(220, 220, 220, 0.38)"
        alignItems="center"
      >
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" backgroundColor="#FFFFFF">
            <Link to={"/"}>
              <Box mt={{ xs: 1, md: 0.5 }} marginRight={"30px"}>
                <img
                  src={logo}
                  style={{
                    width: matches ? "80px" : "95px",
                  }}
                  alt="logo"
                />
              </Box>
            </Link>

            {matches ? (
              <>
                {/* {token ? ( */}
                <>
                  <Box
                    display={{ xs: "flex", md: "none" }}
                    flexGrow={1}
                    justifyContent={"flex-end"}
                  >
                    {anchorElNav === null ? (
                      <IconButton
                        size="large"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleOpenNavMenu}
                      >
                        <MenuIcon sx={{ color: "#000", fontSize: "25px" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="large"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleCloseNavMenu}
                      >
                        <Close sx={{ color: "#000", fontSize: "25px" }} />
                      </IconButton>
                    )}

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav) ?? false}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: "block", md: "none" },
                        ml: "15px",
                        "& .MuiPaper-root": {
                          backgroundColor: "#fff",
                          color: "#000",
                          width: "60%",
                        },
                      }}
                    >
                      {sideArray.map(({ name, to, icon }, i) => (
                        <MenuItem key={i} onClick={handleCloseNavMenu}>
                          <NavLink
                            to={to}
                            style={({ isActive }) => ({
                              ...navStyle,
                              // ...(isActive ? textGraident : undefined),
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            })}
                          >
                            <IconButton
                              sx={{
                                color: "#121520",
                              }}
                            >
                              {icon}
                            </IconButton>
                            <Typography
                              sx={{
                                color: "#121520",
                                fontFamily: "'Open Sans'",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              {name}
                            </Typography>
                          </NavLink>
                        </MenuItem>
                      ))}

                      <Button
                        onClick={logOut}
                        sx={{
                          border: "2px solid  #ECECEC",
                          borderRadius: "14px",
                          backgroundColor: "#fff",
                          ml: "20px",
                          color: "#EF5656",
                          py: "5px",
                          px: "20px",
                        }}
                      >
                        LOGOUT
                      </Button>
                    </Menu>
                  </Box>
                </>
                {/* ) : null} */}
              </>
            ) : (
              <>
                {/* {token ? ( */}
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    flexGrow={1}
                    alignItems="center"
                  >
                    {sideArray.map(({ name, to, icon }, i) => {
                      return (
                        <Box mr={{ md: 2.5, lg: 4 }} key={i}>
                          <NavLink
                            to={to}
                            style={({ isActive }) => ({
                              ...navStyle,
                              ...(isActive ? textGraident : undefined),
                            })}
                          >
                            <IconButton
                              disableRipple
                              sx={{
                                color:
                                  pathname === to
                                    ? " #121520"
                                    : " rgba(18, 21, 32, 0.38)",
                              }}
                            >
                              {icon}
                            </IconButton>
                            <Typography
                              sx={{
                                color:
                                  pathname === to
                                    ? " #121520"
                                    : " rgba(18, 21, 32, 0.38)",
                                fontFamily: "'Open Sans'",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              {name}
                            </Typography>
                          </NavLink>
                        </Box>
                      );
                    })}
                  </Box>

                  <Button
                    onClick={logOut}
                    sx={{
                      display: { xs: "none", md: "block" },
                      border: "2px solid  #ECECEC",
                      borderRadius: "14px",
                      backgroundColor: "#fff",
                      color: "#EF5656",
                      py: "5px",
                      px: "20px",
                    }}
                  >
                    LOG OUT
                  </Button>
                </>
                {/* ) : null} */}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Header;
