export const addToken = (token) => {
  return localStorage.setItem("token", token);
};

export const addCollectionDetail = (collection) => {
  return localStorage.setItem("collection", collection);
};

export const addCollectionProductDeleteDetail = (collection) => {
  return localStorage.setItem("productCollectionId", collection);
};

export const getToken = localStorage.getItem("token") ?? "";
export const collectionDetail =
  localStorage.getItem("collection") ??
  JSON.stringify({
    collectionName: "",
    collectionId: "",
  });

export const getCollectionProductDeleteDetail =
  localStorage.getItem("productCollectionId") ?? "";
