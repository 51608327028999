import React, { useEffect } from "react";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  Container,
  MenuItem,
  Select,
  styled,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { useAddWeb2ProductMutation } from "../store/web2ProductApi";
import {
  handleKeyDown,
  handleWheel,
  actualImgSize,
} from "../services/hadleNumberInput";
import { uploadimage } from "./SmallComponents/Images";

import {
  inputBox,
  inputStyle,
  ToastNotify,
  type,
} from "./SmallComponents/AppComponents";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BackButton } from "./SmallComponents/BackButton";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../store/componentState";
import Loading from "./SmallComponents/loading";

const CustomSelect = styled(Select)({
  background: "#FFFFFF",
  border: "3px solid rgba(82, 89, 96, 0.26)",
  borderRadius: "18px",
  padding: "0 20px",
  width: "100%",
  height: "62px",
  color: "#121520",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 0",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    color: "#121520",
  },

  "& .MuiSelect-icon": {
    color: "rgba(220, 220, 220, 0.69)",
    fontSize: "2rem",
  },
});
const CreateProduct = () => {
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState("");
  const [heatPressType, setHeatPressType] = useState("");
  const [customType, setCustomType] = useState("");
  const [logoImg, setLogoImg] = useState([]);
  const [baseURL, setBaseURL] = useState([]);
  const [loading, setLoading] = useState(false);
  const [heatPress, setHeatPress] = useState(false);
  const [custom, setCustom] = useState(false);
  const [embossing, setEmbossing] = useState(false);
  const [unLimitedStock, setUnLimitedStock] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  // const handleChangeProductType = (event) => {
  //   setProductType(event.target.value);
  // };
  const handleChangeHeatPresstType = (event) => {
    setHeatPressType(event.target.value);
  };
  const handleCustomType = (event) => {
    setCustomType(event.target.value);
  };
  const toastShowing = (displayMessage) => {
    setAlertState({
      open: true,
      message: displayMessage,
      severity: "error",
    });
  };
  const [addProduct] = useAddWeb2ProductMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (productData) => {
    try {
      productData.featured = false;
      productData.embossing = embossing;
      productData.heatPress = heatPress;
      productData.custom = custom;
      productData.unLimitedStock = unLimitedStock;
      const formData = new FormData();

      if (logoImg.length === 0) {
        return toastShowing("Product Images  required");
      }
      if (logoImg.length > 0) {
        logoImg.map((info) => formData.append("file", info));
      }
      if (heatPress) {
        productData.heatPressType = heatPressType;
      } else {
        productData.heatPressType = "";
      }
      if (custom) {
        productData.customType = customType;
      } else {
        productData.customType = "";
      }
      formData.append("data", JSON.stringify(productData));
      setLoading(true);
      const { data, error } = await addProduct(formData);
      if (error) {
        return dispatch(
          openErrorModal({
            open: true,
            errorMessage: error?.data?.message,
          })
        );
      }
      navigate(`/web2saveproduct/${data?.data?._id}`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deleteImage = (image) => {
    const updateBaseUrl = [...baseURL];
    updateBaseUrl[image] = null;
    setBaseURL(updateBaseUrl);
    const updateLogoImg = [...logoImg];
    updateLogoImg[image] = null;
    setLogoImg(updateLogoImg);
    // setBaseURL(
    //   baseURL.filter((item, index) => {
    //     return index !== image;
    //   })
    // );
    // setLogoImg(
    //   logoImg.filter((item, index) => {
    //     return index !== image;
    //   })
    // );
  };

  const handleFile = async (event, i) => {
    if (event.target.files[0]?.size > actualImgSize) {
      if (event?.target?.value) {
        event.target.value = "";
      }
      toastShowing(
        "Sorry, your image exceeds the maximum size allowed. Please upload an image that is smaller than 10 mb."
      );
    } else {
      [...event.target.files].forEach((file) => {
        const updateBaseUrl = [...baseURL];
        updateBaseUrl[i] = URL.createObjectURL(file);
        setBaseURL(updateBaseUrl);
        const updateLogoImg = [...logoImg];
        updateLogoImg[i] = file;
        setLogoImg(updateLogoImg);
      });
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "65px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            md: "row",
          }}
          alignItems={{ xs: "flex-start", md: "center" }}
        >
          <Box sx={{ width: "100px" }}>
            <BackButton />
          </Box>

          <Box
            sx={{
              ml: { xs: 0, md: 31 },
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              textAlign={{ xs: "center", md: "left" }}
              textTransform={"uppercase"}
              mt={{ xs: 2, md: 0 }}
            >
              Create Product
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              margin: "auto",
              width: {
                xs: "90%",
                md: "690px",
              },
            }}
          >
            <Box ml={{ xs: 0, md: 12.5 }} mt={"59px"}>
              <Typography sx={type}>Name *</Typography>
              <Box>
                <input
                  type="text"
                  placeholder="Product Name"
                  {...register("name", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    border:
                      errors.name && errors.name.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
              </Box>
              {errors.name && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}
              <Typography sx={type}>IMAGES *</Typography>
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{
                  xs: "column",
                  md: "row",
                }}
                gap="10px"
              >
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        background: "#F5F5F5",
                        border: "3px dashed rgba(82, 89, 96, 0.26)",
                        borderRadius: "18px",
                        width: "167px",
                        height: "155px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <label
                        htmlFor={`logoImg${index}`}
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <input
                          type="file"
                          id={`logoImg${index}`}
                          accept="image/*"
                          {...register("images")}
                          onChange={(e) => handleFile(e, index)}
                          style={{
                            display: "none",
                          }}
                        />
                        {baseURL[index] ? (
                          <Box sx={{ position: "relative" }}>
                            <IconButton
                              onClick={(e) => deleteImage(index)}
                              sx={{
                                position: "absolute",
                                width: "30px",
                                height: "30px",
                                top: "15px",
                                right: "20px",
                              }}
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </IconButton>

                            <img
                              src={baseURL[index]}
                              alt=""
                              style={{
                                width: "167px",
                                height: "158px",
                                objectFit: "fill",
                              }}
                              loading="lazy"
                            />
                          </Box>
                        ) : (
                          <>
                            {" "}
                            <img
                              src={uploadimage}
                              type="file"
                              alt="imgaeuploader"
                              // style={{ objectFit: "cover" }}
                            />
                            <Typography
                              sx={{
                                color: "#121520",
                                fontWeight: 400,
                                my: "10px",
                                fontSize: "16px !important",
                              }}
                            >
                              Upload image
                            </Typography>
                          </>
                        )}
                      </label>
                    </Box>
                  );
                })}
              </Box>
              <Typography sx={type}>Description *</Typography>
              <Box>
                <textarea
                  type="text"
                  // placeholder="Collection Name"
                  {...register("description", {
                    required: true,
                  })}
                  spellCheck="false"
                  style={{
                    ...inputStyle,
                    height: "204px",
                    border:
                      errors.description &&
                      errors.description.type === "required"
                        ? "3px solid #EF5656"
                        : "3px solid rgba(82, 89, 96, 0.26)",
                  }}
                />
              </Box>
              {errors.description && (
                <Typography
                  mt={0.5}
                  fontSize={"12px !important"}
                  color="#EF5656"
                >
                  This field is required
                </Typography>
              )}

              <Box
                width={{
                  xs: "100%",
                  md: "auto",
                  // border:"2px solid red"
                }}
              >
                <Typography
                  sx={{
                    ...type,
                    fontSize: "16px !important",
                  }}
                >
                  Quantity in stock *
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="limited"
                    name="radio-buttons-group"
                    row
                  >
                    <FormControlLabel
                      value="unLimited"
                      sx={{
                        "	.Mui-focused": {
                          color: "red",
                        },
                      }}
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#A4ABB2",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ color: "#A4ABB2" }}>
                          Unlimited
                        </Typography>
                      }
                      onChange={() => {
                        setUnLimitedStock(true);
                      }}
                    />
                    <FormControlLabel
                      value="limited"
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#A4ABB2",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ color: "#A4ABB2" }}>
                          limited
                        </Typography>
                      }
                      onChange={() => {
                        setUnLimitedStock(false);
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                {!unLimitedStock ? (
                  <>
                    <Box>
                      <input
                        type="number"
                        placeholder="0"
                        {...register("totalQuantity", {
                          required: true,
                        })}
                        min="0"
                        spellCheck="false"
                        onKeyDown={handleKeyDown}
                        onWheel={handleWheel}
                        style={{
                          ...inputStyle,
                          border:
                            errors.totalQuantity &&
                            errors.totalQuantity.type === "required"
                              ? "3px solid #EF5656"
                              : "3px solid rgba(82, 89, 96, 0.26)",
                        }}
                      />
                    </Box>
                    {errors.totalQuantity && (
                      <Typography
                        mt={0.5}
                        fontSize={"12px !important"}
                        color="#EF5656"
                      >
                        This field is required
                      </Typography>
                    )}
                  </>
                ) : null}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{
                  xs: "column",
                  md: "row",
                }}
                columnGap="30px"
              >
                <Box
                  width={{
                    xs: "100%",
                    md: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      ...type,
                      fontSize: "16px !important",
                    }}
                  >
                    Price Unit *
                  </Typography>
                  <Box>
                    <input
                      readOnly
                      type="number"
                      placeholder="$USD"
                      // {...register("priceUnit", {
                      //   required: true,
                      // })}
                      spellCheck="false"
                      style={{
                        ...inputStyle,
                        border:
                          errors.priceUnit &&
                          errors.priceUnit.type === "required"
                            ? "3px solid #EF5656"
                            : "3px solid rgba(82, 89, 96, 0.26)",
                      }}
                    />
                  </Box>
                  {errors.priceUnit && (
                    <Typography
                      mt={0.5}
                      fontSize={"12px !important"}
                      color="#EF5656"
                    >
                      This field is required
                    </Typography>
                  )}
                </Box>
                <Box
                  width={{
                    xs: "100%",
                    md: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      ...type,
                      fontSize: "16px !important",
                    }}
                  >
                    Unit Prtice *
                  </Typography>
                  <Box>
                    <input
                      type="text"
                      placeholder="0"
                      {...register("price", {
                        required: true,
                        pattern: {
                          value: /^[0-9]+(\.[0-9]{1,2})?$/,
                          message: "invalid number",
                        },
                      })}
                      min="0"
                      onKeyDown={handleKeyDown}
                      onWheel={handleWheel}
                      spellCheck="false"
                      style={{
                        ...inputStyle,
                        border:
                          errors.price && errors.price.type === "required"
                            ? "3px solid #EF5656"
                            : "3px solid rgba(82, 89, 96, 0.26)",
                      }}
                    />
                  </Box>
                  {errors.price && (
                    <Typography
                      mt={0.5}
                      fontSize={"12px !important"}
                      color="#EF5656"
                    >
                      This field is required
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* <Typography sx={type}>Product Type *</Typography>
              <CustomSelect
                displayEmpty
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={productType}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: "#ffff",
                      },
                    },
                  },
                }}
                onChange={handleChangeProductType}
              >
                <MenuItem value="" disabled sx={{ display: "none" }}>
                  Selected Product Type
                </MenuItem>
                {["Product Type 1", "Product Type 2", "Product Type 3"].map(
                  (name, i) => {
                    return (
                      <MenuItem
                        value={name}
                        key={i}
                        sx={{
                          color: "#121520",
                          fontSize: "14px",
                        }}
                      >
                        {name}
                      </MenuItem>
                    );
                  }
                )}
              </CustomSelect> */}

              <Typography
                sx={{
                  ...type,
                  mt: "48px",
                }}
              >
                Product Customization
              </Typography>
              <Box
                sx={{
                  background: "#FFFFFF",
                  border: "3px solid rgba(82, 89, 96, 0.26)",
                  borderRadius: "18px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    borderBottom:
                      heatPress && checkBox === "heat"
                        ? "3px solid rgba(82, 89, 96, 0.26)"
                        : "0px solid",
                    p: 2,
                  }}
                  alignItems="center"
                >
                  <Checkbox
                    // checked={checkBox === "heat"}
                    checked={heatPress && checkBox === "heat"}
                    icon={
                      <RadioButtonUnchecked
                        sx={{
                          color: "rgba(18, 21, 32, 0.69)",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckCircle
                        sx={{
                          color: "#15BDAC",
                        }}
                      />
                    }
                    onChange={(e) => {
                      setCheckBox("heat");
                      setEmbossing(false);
                      setCustom(false);
                      setHeatPress(e.target.checked);
                    }}
                  />
                  <Typography variant="subtypo" fontSize="18px !important">
                    Heatpress
                  </Typography>
                </Box>
                {checkBox === "heat" && heatPress && (
                  <Box
                    mx={{
                      xs: 2,
                      md: 8,
                    }}
                    mt="18px"
                  >
                    <Box mt="14px" mb="24px">
                      <CustomSelect
                        displayEmpty
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={heatPressType}
                        inputProps={{
                          MenuProps: {
                            MenuListProps: {
                              sx: {
                                backgroundColor: "#ffff",
                              },
                            },
                          },
                        }}
                        onChange={handleChangeHeatPresstType}
                      >
                        <MenuItem value="" disabled sx={{ display: "none" }}>
                          Select Type
                        </MenuItem>
                        {["Front & Back Custom", "Only One Side Custom"].map(
                          (name, i) => {
                            return (
                              <MenuItem
                                value={name}
                                key={i}
                                sx={{
                                  color: "#121520",
                                  fontSize: "14px",
                                }}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </CustomSelect>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  ...inputBox,
                  mt: 2,
                }}
              >
                <Checkbox
                  checked={embossing && checkBox === "embossing"}
                  icon={
                    <RadioButtonUnchecked
                      sx={{
                        color: "rgba(18, 21, 32, 0.69)",
                      }}
                    />
                  }
                  checkedIcon={
                    <CheckCircle
                      sx={{
                        color: "#15BDAC",
                      }}
                    />
                  }
                  onChange={(e) => {
                    setCheckBox("embossing");
                    setCustom(false);
                    setHeatPress(false);
                    setEmbossing(e.target.checked);
                  }}
                />
                <Typography variant="subtypo" fontSize="18px !important">
                  Embossing
                </Typography>
              </Box>

              <Box
                sx={{
                  background: "#FFFFFF",
                  border: "3px solid rgba(82, 89, 96, 0.26)",
                  borderRadius: "18px",
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    borderBottom:
                      custom && checkBox === "custom"
                        ? "3px solid rgba(82, 89, 96, 0.26)"
                        : "0px solid",
                    p: 2,
                  }}
                  alignItems="center"
                >
                  <Checkbox
                    checked={custom && checkBox === "custom"}
                    icon={
                      <RadioButtonUnchecked
                        sx={{
                          color: "rgba(18, 21, 32, 0.69)",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckCircle
                        sx={{
                          color: "#15BDAC",
                        }}
                      />
                    }
                    onChange={(e) => {
                      setCheckBox("custom");
                      setHeatPress(false);
                      setEmbossing(false);
                      setCustom(e.target.checked);
                    }}
                  />
                  <Typography variant="subtypo" fontSize="18px !important">
                    Custom
                  </Typography>
                </Box>
                {checkBox === "custom" && custom && (
                  <Box
                    mx={{
                      xs: 2,
                      md: 8,
                    }}
                    mt="18px"
                  >
                    <Box mt="14px" mb="24px">
                      <CustomSelect
                        displayEmpty
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={customType}
                        inputProps={{
                          MenuProps: {
                            MenuListProps: {
                              sx: {
                                backgroundColor: "#ffff",
                              },
                            },
                          },
                        }}
                        onChange={handleCustomType}
                      >
                        <MenuItem value="" disabled sx={{ display: "none" }}>
                          Select Type
                        </MenuItem>
                        {["Front & Back Custom", "Only One Side Custom"].map(
                          (name, i) => {
                            return (
                              <MenuItem
                                value={name}
                                key={i}
                                sx={{
                                  color: "#121520",
                                  fontSize: "14px",
                                }}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </CustomSelect>
                    </Box>
                  </Box>
                )}
              </Box>
              {/* <Typography
                sx={{
                  pt: "44px",
                  fontSize: "16px !important",
                  color: "#121520",
                }}
              >
                Featured Collection
              </Typography> */}
              {/* <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  borderBottom: "3px solid rgba(82, 89, 96, 0.26)",
                  pb: "40px",
                  mt: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px !important",
                    lineHeight: "21px",
                    letterSpacing: "0.03em",
                    color: "rgba(18, 21, 32, 0.69)",
                  }}
                >
                  Lörem ipsum semimorär åmarad viktiga för att vadöns <br />
                  tasigförsamhet.{" "}
                </Typography>

                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={featuredProduct}
                      sx={{ m: 1 }}
                      onChange={handleFeatureSwitch}
                    />
                  }
                />
              </Box> */}
              <Box display="flex" justifyContent="flex-end" mt={"30px"}>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    border: "2px solid rgba(82, 89, 96, 0.26)",
                    borderRadius: "10px",
                    textTransform: "uppercase",
                    color: "#121520",
                    mr: "8px",
                    py: "10px",
                    px: "30px",
                    fontSize: "16px !important",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="gradient"
                  sx={{
                    px: "30px",
                    py: "10px",
                  }}
                >
                  Save Product
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default CreateProduct;
